import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@services/index';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user:any = this.authenticationService.userValue;
        // logged in & has role so return true
        if (user) {
            return true;
        }

        // not logged in so redirect to login page with the return url
        const defaultPage = user && user.roles.length === 1 && user.roles[0] === 'REVIEWER' ? '/event-report' : '/dashboard';
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl:  defaultPage} });
        return false;
    }
}