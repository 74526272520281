import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Constants from '@helpers/constants';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, UsersRolesService } from '@services/index';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent implements OnInit {
    user: any;
    menu = Constants.MENU_ITEMS;
    selectedLang = "en";
    languages = ["en", "fr"];

    constructor(public layoutService: LayoutService, public translate: TranslateService,
        private authenticationService: AuthenticationService,
        private usersRolesService: UsersRolesService, private router: Router,
        private authService: AuthenticationService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.authenticationService.userSubject.subscribe(user => {
            this.user = user;
        });
        this.setUserProfile();
    }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    signOut() {
        this.authService.logout();
    }
    public logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('uiroles');
        localStorage.removeItem('token');
        sessionStorage.clear();
        location.reload();
    }

    private setUserProfile() {
        var userData = localStorage.getItem('user');
        this.user = JSON.parse(userData || '');
        Constants.setLoggedUser(userData);
        this.usersRolesService.setFleets();
    }


    setMenuItem() {
        var loggedUserType = 'STANDARD'
        const user = Constants.getLoggedUser()
        if ('userType' in user && user['userType'] != undefined) {
            loggedUserType = user['userType'];
        }
    }

    resetMenuItem() {
        this.menu.forEach(item => {
            // item['hidden'] = true;
        });
    }

    changeLang(selectedLang: any) {
        this.translate.use(selectedLang.value);
    }

    public navigateToSettings(): void {
        this.router.navigate(["../settings/account-settings"], { relativeTo: this.route })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.user) {
            this.user = changes.user;
        }
    }
}