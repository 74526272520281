import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DeviceHealthService {
    private deviceHealthUrl = environment.apiUrl + '/device_health_logs';

    constructor(private http: HttpClient) { }

    getDeviceHealthLogs(fromDate: string, toDate: string, page: number = 0, rows: number = 10, isTracker: number = 0, deviceIds: string[] = [''], healthStatus: number[] = [], ignition: number = -1): Observable<any> {
        let params: any = {
            from_date: fromDate,
            to_date: toDate,
            page_number: page,
            page_limit: rows,
            is_tracker: isTracker,
            device_dds: deviceIds.length ? JSON.stringify(deviceIds) : '[""]',
            health_status: JSON.stringify(healthStatus)
        };
        if (ignition !== -1)
            params.ignition = ignition;
        return this.http.get(this.deviceHealthUrl, { params: params });
    }

    updateManualReview(device_log_id: string, log_id: string, review_status: number, comments: string): Observable<any> {
        var device_url_by_device_id = this.deviceHealthUrl + '/' + device_log_id;
        let params = { health_log_id: log_id, health_log_review: review_status, comment: comments };
        return this.http.patch(device_url_by_device_id, null, { params: params })
            .pipe(
                map((res: any) => {
                    return res;
                }),
                catchError((error) => {
                    return throwError(error);
                }),
            );
    }
}