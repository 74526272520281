
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TripsService {
    private baseFetchTripsUrl = environment.apiUrl + '/trips/list';
    private baseGetTripsUrl = environment.apiUrl + '/trips';

    constructor(private http: HttpClient) { }

    getTripsList(from_date: string, to_date: string, fleet_id: any, pagination: any, device_id?: any, driver_id?: any, 
        distanceRange?: any, durationRange?: any, idlingDurationRange?: any): Observable<any> {
        const body = {
            fleet_id,
            to_date,
            from_date,
            pagination,
            device_id,
            driver_id,
            min_distance: distanceRange ? distanceRange[0] : undefined,
            max_distance: distanceRange ? distanceRange[1] : undefined,
            min_duration: durationRange ? durationRange[0] : undefined,
            max_duration: durationRange ? durationRange[1] : undefined,
            min_idling_duration: idlingDurationRange ? idlingDurationRange[0] : undefined,
            max_idling_duration: idlingDurationRange ? idlingDurationRange[1] : undefined
        }
        return this.http.post(this.baseFetchTripsUrl, body);
    }

    getAllTrips(): Observable<any> {
        return this.http.get(this.baseGetTripsUrl);
    }

    getTripDetails(trip_id: string): Observable<any> {
        return this.http.get(`${this.baseGetTripsUrl}/${trip_id}`);
    }
}