import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard, RoleGuard } from 'src/app/demo/helpers';
import { AppLayoutComponent } from './layout/app.layout.component';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled',
    useHash: false
};

const routes: Routes = [
    {
        path: '', component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: '/auth/login', pathMatch: 'full', },
            {
                path: 'dashboard',
                canActivate: [RoleGuard],
                data: {
                    breadcrumb: '', route: 'dashboard', basePageId: 1000,
                    expectedRole: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'VIEWER', 'DEVICE_OPS']
                },
                loadChildren: () => import('./demo/components/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'event-report',
                canActivate: [RoleGuard],
                data: {
                    breadcrumb: 'Event Report', route: 'event-report', basePageId: 2000,
                    expectedRole: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'REVIEWER', 'VIEWER']
                },
                loadChildren: () => import('./demo/components/event-report/event-report.module').then(m => m.EventReportModule)
            },
            {
                path: 'driver-analytics',
                canActivate: [RoleGuard],
                data: {
                    breadcrumb: 'Driver Analytics', route: 'driver-analytics', basePageId: 3000,
                    expectedRole: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'REVIEWER', 'VIEWER']
                },
                loadChildren: () => import('./demo/components/driver-analytics/driver-analytics.module').then(m => m.DriverAnalyticsModule)
            },
            {
                path: 'trips',
                canActivate: [RoleGuard],
                data: {
                    breadcrumb: 'Trips', route: 'trips', basePageId: 7000,
                    expectedRole: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'REVIEWER', 'VIEWER']
                },
                loadChildren: () => import('./demo/components/trips/trips.module').then(m => m.TripsModule)
            },
            {
                path: 'admin',
                canActivate: [RoleGuard],
                data: {
                    breadcrumb: 'Administration', route: 'admin', basePageId: 5000,
                    expectedRole: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'DEVICE_OPS']
                },
                loadChildren: () => import('./demo/components/admin/admin.module').then(m => m.AdminModule)
            },
            {
                path: 'reports',
                canActivate: [RoleGuard],
                data: {
                    breadcrumb: 'Reports', route: 'reports', basePageId: 8000,
                    expectedRole: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'DEVICE_OPS']
                },
                loadChildren: () => import('./demo/components/reports/reports.module').then(m => m.ReportsModule)
            },
            {
                path: 'smart-enroll',
                canActivate: [RoleGuard],
                data: {
                    breadcrumb: 'Smart Enroll', route: 'smart-enroll', basePageId: 4000,
                    expectedRole: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'REVIEWER'],
                },
                loadChildren: () => import('./demo/components/smart-enroll/smart-enroll.module').then(m => m.SmartEnrollModule)
            },
            {
                path: 'settings',
                canActivate: [RoleGuard],
                data: {
                    breadcrumb: 'Settings', route: 'settings', basePageId: 6000,
                    expectedRole: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'REVIEWER', 'VIEWER', 'DEVICE_OPS']
                },
                loadChildren: () => import('./demo/components/settings/settings.module').then(m => m.SettingsModule)
            },
        ]
    },
    { path: 'auth', data: { breadcrumb: 'Auth' }, loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule) },
    { path: 'landing', loadChildren: () => import('./demo/components/landing/landing.module').then(m => m.LandingModule) },
    { path: 'notfound', loadChildren: () => import('./demo/components/notfound/notfound.module').then(m => m.NotfoundModule) },
    { path: '**', redirectTo: '/notfound' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
