// role.guard.ts
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { RoleService } from '@services/index';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard {

    constructor(private roleService: RoleService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const expectedRole = route.data.expectedRole;

        if (this.roleService.hasRole(expectedRole)) {
            return true;
        } else {
            const roles = JSON.parse(localStorage.getItem('user') || '{}').roles;
            if (roles.length === 1 && (roles?.includes('REVIEWER'))) {
                this.router.navigate(['/event-report']);
            } else {
                this.router.navigate(['/dashboard']);
            }
            this.router.navigate(['/auth/access']);
            return false;
        }
    }
}