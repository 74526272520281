export const today = "2023-04-02";

export const enum RegExp {
    URL_REGEX = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
    URL_REGEX_WITH_AT = '(https?://)?([\\w.-]+@[\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?'
}

export const SPEED_UNITS = [
    { name: 'Metric (Kilometers)', value: 1 },
    { name: 'Imperial (Miles)', value: 2 }
]

export const DEFAULT_SETTINGS: any = {
    'min_speed': 20,
    'event_mapping': {},
    'event_weight_mapping': [0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1],
    'speed_unit': 1,
    'enable_face_obfuscation': false,
    'enable_background_obfuscation': false
};

export const DEFAULT_LABEL: any = {
    'accident_avoidance_default_compound_event': 'Accident Avoidance',
    'multi_severe_primary_event': 'Compound Event',
    'compound event': 'Compound Event',
    'accident_avoidance_event': 'Accident Avoidance'
};

export const VALID_DEFAULT_COMPOUND_EVENT: any = {
    'multi_severe_primary_event': 'Compound Event',
};

export const ACCIDENCE_AVOIDANCE_EVENT: any = {
    'accident_avoidance_default_compound_event': 'Accident Avoidance',
};

export const DRIVER_SCORECARD_EVENTS = [
    { name: "Inattention", weightage: 0.1 },
    { name: "Phone", weightage: 0.1 },
    { name: "Obstruction", weightage: 0.1 },
    { name: "Fatigue", weightage: 0.1 },
    { name: "Mask", weightage: 0.1 },
    { name: "Eating Drinking", weightage: 0.1 },
    { name: "Smoking", weightage: 0.1 },
    { name: "FCW", weightage: 0.1 },
    { name: "Lane Departure", weightage: 0.1 },
    { name: "Stop Sign", weightage: 0.1 },
    { name: "Traffic Sign", weightage: 0.1 },
    { name: "Seat Belt", weightage: 0.1 },
    { name: "Following Distance Warning", weightage: 0.1 }
]

export const WIDGET_EXPORT_MAPPING: any = {
    1005: [1005],
    1010: [1010, 10101, 10102],
    1015: [1015, 10101],
    1020: [1020, 10102],
    1025: [1025],
    1030: [1030],
    1035: [1035],
    1040: [5005, 5010, 10102],
    2005: [2005],
    2010: [2010],
    3005: [10102],
    3010: [3000, 30001, 10102],
    3015: [1025, 10103],
    5005: [5005],
    5010: [5010],
    5020: [5020],
    5025: [5025]
}

export const WIDGET_ID_NAME: any = {
    1005: "Opportunity Assessment Report",
    1010: "Liability Assessment Report",
    1015: "Driver Assessment Report",
    1020: "Driver Scorecard",
    1025: "Driver Coaching Report",
    1030: "Event Analysis Report",
    1035: "Performance Summary",
    2005: "Events Executive Summary",
    2010: "Snapshot Report Videos",
    3000: "Driver Trip Report",
    3005: "Driver List",
    5005: "Fleet Management",
    5010: "Device Management",
    5020: "User Management",
    5025: "Role Management",
    10101: "Driver Risk",
    10102: "Driver List",
    10103: "Driver Details",
    30001: "Event Review Videos"
}

export const WIDGET_API_MAPPING: any = {
    1005: 'fleet_performance',
    1010: 'fleet_coaching_summary',
    1015: 'driver_assessment_summary',
    1025: 'driver_coaching_report',
    1035: 'fleet_coaching_summary',
    1030: 'fleet_coaching_summary',
    10101: 'driver_scorecard',
    1020: 'driver_scorecard',
    3000: 'fleet_aggregation',
    2005: 'fleet_coaching_summary',
    2010: 'events_videos',
    30001: 'events_videos',
    10102: 'drivers',
    10103: 'drivers',
    5005: 'fleets',
    5010: 'devices',
    5020: 'users',
    5025: 'roles'
}

export const REPORT_MANDATORY_KEYS: any = {
    "fleet_performance": {
        "from_date": "",
        "to_date": "",
        "fleet_id": []
    },
    "fleet_coaching_summary": {
        "from_date": "",
        "to_date": "",
        "fleet_id": [],
        "events": ["obstruction", "seat_belt", "inattention", "fatigue", "phone", "eating_drinking", "smoking", "mask", "forward_collision_warning", "dashed_lane_departure_warning", "solid_lane_departure_warning", "following_distance_warning", "pedestrian_collision_warning", "stop_sign_violation", "traffic_light_violation", "speeding", "braking", "acceleration", "cornering"]
    },
    "driver_assessment_summary": {
        "from_date": "",
        "to_date": "",
        "fleet_id": [],
        "duration_type": "MONTHLY"
    },
    "driver_coaching_report": {
        "from_date": "",
        "to_date": "",
        "fleet_id": [],
        "pagination": {
            "sort_order": "asc",
            "items_per_page": 1000000,
            "page_number": 1,
            "sort_field": "reviewed_events"
        }
    },
    "driver_scorecard": {
        "from_date": "",
        "to_date": "",
        "fleet_id": [],
        "duration_type": "MONTHLY",
        "pagination": {
            "sort_order": "desc",
            "items_per_page": 1000000,
            "page_number": 1,
            "sort_field": "total_events"
        }
    },
    'fleet_coaching_report': {
        "from_date": "",
        "to_date": "",
        "fleet_id": [],
        "pagination": {
            "sort_order": "desc",
            "items_per_page": 1000000,
            "page_number": 1,
            "sort_field": "reviewed_events"
        }
    },
    "fleet_aggregation": {
        "from_date": "",
        "to_date": "",
        "fleet_id": []
    },
    "events_videos": {
        "from_date": "",
        "to_date": "",
        "driver_id": [],
        "event_label": [],
        "pagination": {
            "sort_order": "desc",
            "items_per_page": 1000000,
            "page_number": 1,
            "sort_field": "timestamp"
        }
    },
    "drivers": {
        "fleet_id": [],
        "driver_id": [],
        "pagination": {
            "items_per_page": 1000000,
            "page_number": 1
        }
    },
    "fleets": "fleet_ids",
    "devices": "fleet_ids",
    "users": "",
    "roles": ""
}

export const HEAD_POSE_ANGLE_THRESHOLD = [
    { yaw_min: -45, yaw_max: 45, pitch_min: -40, pitch_max: 40 }
]

export const SPEED_THRESHOLD_INATTENTION = [
    { speed: "20 - 30", threshold: 0.8, id: 1 },
    { speed: "30 - 50", threshold: 0.8, id: 2 },
    { speed: "50 - 65", threshold: 0.8, id: 3 },
    { speed: "65 >", threshold: 0.8, id: 4 },
]

export const TIME_RANGE_FILTER_OPTIONS = [
    { name: "Today", value: 0, string: "this_day" },
    { name: "Week", value: 7, string: "this_week" },
    { name: "Month", value: 30, string: "this_month" },
    { name: "60 days", value: 60, string: "this_60days" },
    { name: "Quarter", value: 90, string: "this_quarter" },
    { name: "6 Months", value: 180, string: "this_6month" },
    { name: "Year", value: 365, string: "this_year" },
    { name: "---", string: "---", disabled: true },
    { name: "Select date range", value: 10 },
    { name: "Select a Week", value: 70 },
    { name: "Select a Month", value: 300 },
]

export const TIME_FILTER_TO_DATA_MAPPING = [
    { key: "month_11", value: 300, start: "2022-11-01", end: "2022-11-30" },
    { key: "month_12", value: 300, start: "2022-12-01", end: "2022-12-31", chartLabels: ["week_49", "week_50", "week_51", "week_52"] },
    { key: "month_1", value: 300, start: "2023-01-01", end: "2023-01-30", chartLabels: ["week_1", "week_2", "week_3", "week_4"] },
    { key: "month_2", value: 300, start: "2023-02-01", end: "2023-02-26", chartLabels: ["week_5", "week_6", "week_7", "week_8"] },
    { key: "month_3", value: 300, start: "2023-03-01", end: "2023-03-31", chartLabels: ["week_9", "week_10", "week_11", "week_12"] },
    { key: "month_4", value: 300, start: "2023-04-01", end: "2023-04-30", chartLabels: ["week_13", "week_14", "week_15", "week_16"] },
    { key: "week_13", value: 70, start: "2023-03-27", end: "2023-04-02", chartLabels: ["2023-03-27", "2023-03-28", "2023-03-29", "2023-03-30", "2023-03-31", "2023-04-01", "2023-04-02"] },
    { key: "week_12", value: 70, start: "2023-03-20", end: "2023-03-26", chartLabels: ["2023-03-20", "2023-03-21", "2023-03-22", "2023-03-23", "2023-03-24", "2023-03-25", "2023-03-26"] },
    { key: "week_11", value: 70, start: "2023-03-13", end: "2023-03-19", chartLabels: ["2023-03-13", "2023-03-14", "2023-03-15", "2023-03-16", "2023-03-17", "2023-03-18", "2023-03-19"] },
    { key: "week_10", value: 70, start: "2023-03-06", end: "2023-03-12", chartLabels: ["2023-03-06", "2023-03-07", "2023-03-08", "2023-03-09", "2023-03-10", "2023-03-11", "2023-03-12"] },
    { key: "week_9", value: 70, start: "2023-02-27", end: "2023-03-05", chartLabels: ["2023-02-27", "2023-02-28", "2023-03-01", "2023-03-02", "2023-03-03", "2023-03-04", "2023-03-05"] },
    { key: "week_8", value: 70, start: "2023-02-20", end: "2023-02-26", chartLabels: ["2023-02-20", "2023-02-21", "2023-02-22", "2023-02-23", "2023-02-24", "2023-02-25", "2023-02-26"] },
    { key: "week_7", value: 70, start: "2023-02-13", end: "2023-02-19", chartLabels: ["2023-02-13", "2023-02-14", "2023-02-15", "2023-02-16", "2023-02-17", "2023-02-18", "2023-02-19"] },
    { key: "week_6", value: 70, start: "2023-02-06", end: "2023-02-12", chartLabels: ["2023-02-06", "2023-02-07", "2023-02-08", "2023-02-09", "2023-02-10", "2023-02-11", "2023-02-12"] },
    { key: "week_5", value: 70, start: "2023-01-30", end: "2023-02-05", chartLabels: ["2023-01-30", "2023-01-31", "2023-02-01", "2023-02-02", "2023-02-03", "2023-02-04", "2023-02-05"] },
    { key: "week_4", value: 70, start: "2023-01-23", end: "2023-01-29", chartLabels: ["2023-01-23", "2023-01-24", "2023-01-25", "2023-01-26", "2023-01-27", "2023-01-28", "2023-01-29"] },
    { key: "week_3", value: 70, start: "2023-01-16", end: "2023-01-22", chartLabels: ["2023-01-16", "2023-01-17", "2023-01-18", "2023-01-19", "2023-01-20", "2023-01-21", "2023-01-22"] },
    { key: "week_2", value: 70, start: "2023-01-09", end: "2023-01-15", chartLabels: ["2023-01-09", "2023-01-10", "2023-01-11", "2023-01-12", "2023-01-13", "2023-01-14", "2023-01-15"] },
    { key: "week_1", value: 70, start: "2023-01-02", end: "2023-01-08", chartLabels: ["2023-01-02", "2023-01-03", "2023-01-04", "2023-01-05", "2023-01-06", "2023-01-07", "2023-01-08"] },
    { key: "week_52", value: 70, start: "2022-12-26", end: "2023-01-01", chartLabels: ["2022-12-26", "2022-12-27", "2022-12-28", "2022-12-29", "2022-12-30", "2022-12-31", "2023-01-01"] },
    { key: "2023-04-02", value: 1, start: "2023-04-02", end: "2023-04-02", chartLabels: ["2023-04-02"] },
    { key: "2023-04-01", value: 1 },
    { key: "week_13", value: 7, start: "2023-03-27", end: "2023-04-02", chartLabels: ["2023-03-27", "2023-03-28", "2023-03-29", "2023-03-30", "2023-03-31", "2023-04-01", "2023-04-02"] },
    { key: "week_12", value: 7, start: "2023-03-19", end: "2023-03-25" },
    { key: "month_4", value: 30, start: "2023-03-01", end: "2023-03-31", chartLabels: ["week_13", "week_12", "week_11", "week_10"] },
    { key: "month_3", value: 30, start: "2023-02-01", end: "2023-02-26" },
    { key: "this_2month", value: 60, start: "2023-02-01", end: "2023-03-31", chartLabels: ["week_13", "week_12", "week_11", "week_10", "week_9", "week_8", "week_7", "week_6"] },
    { key: "last_2month", value: 60, start: "2022-12-01", end: "2023-01-31" },
    { key: "quater_2", value: 90, start: "2023-01-01", end: "2023-02-26", chartLabels: ["month_4", "month_3", "month_2"] },
    { key: "quater_1", value: 90, start: "2022-12-01", end: "2022-12-31" },
    { key: "this_6month", value: 180, start: "2022-12-01", end: "2023-03-31", chartLabels: ["month_4", "month_3", "month_2", "month_1", "month_12", "month_11"] },
    { key: "last_6month", value: 180, start: "2022-12-01", end: "2023-03-31", chartLabels: ["month_10", "month_9", "month_8", "month_7", "month_6", "month_5"] },
    { key: "this_year", value: 365, start: "2022-12-01", end: "2023-04-02", chartLabels: ["month_4", "month_3", "month_2", "month_1", "month_12", "month_11"] },
];

export const EVENT_MAPPING = [
    "Inattention",
    "Phone",
    "Obstruction",
    "Fatigue",
    "Mask",
    "eatingDrinking",
    "Smoking",
    "Forward Collision Warning",
    "Lane Departure Warning",
    "Stop Signal",
    "Traffic Light",
    "Seat Belt",
    "Follow Distance Warning"
]

export const DOCUMENT_STYLE = getComputedStyle(document.documentElement);

export const CHART_BG_COLORS = [
    DOCUMENT_STYLE.getPropertyValue('--chart-color-1'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-2'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-3'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-4'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-5'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-6'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-7'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-8'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-9'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-10'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-11'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-12'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-13'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-14'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-15'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-16'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-17'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-18'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-8'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-9'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-10'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-1'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-2'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-3'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-4'),
    DOCUMENT_STYLE.getPropertyValue('--chart-color-5'),
]

export const CURRENCIES = [
    { name: "USD", value: "USD", symbol: "$" },
    { name: "EUR", value: 'EUR', symbol: "€" },
    { name: "ZAR", value: 'ZAR', symbol: "R" },
    { name: "INR", value: 'INR', symbol: "₹" },
    { name: "CAD", value: 'CAD', symbol: "CAD" },
    { name: "AUD", value: 'AUD', symbol: "AUD" },
    { name: "GBP", value: 'GBP', symbol: "GBP" },
]

export const TIMEZONES = [
    { name: 'Hawaii-Aleutian Standard Time (HAST) (GMT-10)', value: 'Pacific/Honolulu' },
    { name: 'Alaska Standard Time (AKST) (GMT-9)', value: 'America/Anchorage' },
    { name: 'Pacific Standard Time (PST) (GMT-8)', value: 'America/Los_Angeles' },
    { name: 'Mountain Standard Time (MST) (GMT-7)', value: 'America/Denver' },
    { name: 'Central Standard Time (CST) (GMT-6)', value: 'America/Chicago' },
    { name: 'Eastern Standard Time (EST) (GMT-5)', value: 'America/New_York' },
    { name: 'Atlantic Standard Time (AST) (GMT-4)', value: 'America/Halifax' },
    { name: 'Newfoundland Standard Time (NST) (GMT-3:30)', value: 'America/St_Johns' },
    { name: 'Greenwich Mean Time (GMT) (GMT+0)', value: 'Europe/London' },
    { name: 'South Africa Standard Time (SAST) (GMT+2)', value: 'Africa/Johannesburg' },
    { name: 'India Standard Time (IST) (GMT+5:30)', value: 'Asia/Kolkata' },
    { name: 'Australian Western Standard Time (AWST) (GMT+8)', value: 'Australia/Perth' },
    { name: 'Australian Central Standard Time (ACST) (GMT+9:30)', value: 'Australia/Adelaide' },
    { name: 'Australian Eastern Standard Time (AEST) (GMT+10)', value: 'Australia/Sydney' }
];

export const FUEL_UNITS = [
    { name: "Litres", value: 0 },
    { name: "Gallons", value: 1 },
]

export var MENU_ITEMS = [
    {
        label: 'Home',
        icon: 'pi pi-home',
        routerLink: ['/dashboard'],
        id: 'dashboard',
        permission: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'VIEWER', 'DEVICE_OPS'],
    },
    {
        label: 'Event Report',
        icon: 'pi pi-video',
        routerLink: ['/event-report'],
        id: 'event-report',
        permission: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'REVIEWER', 'VIEWER']
    },
    {
        label: 'Driver Analytics',
        icon: 'pi pi-users',
        routerLink: ['/driver-analytics'],
        id: 'driver-analytics',
        permission: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'REVIEWER', 'VIEWER']
    },
    {
        label: 'Trips',
        icon: 'pi pi-arrow-right-arrow-left',
        routerLink: ['/trips'],
        id: 'trips',
        permission: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'REVIEWER', 'VIEWER']
    },
    {
        label: 'Smart Enroll',
        icon: 'pi pi-user-plus',
        routerLink: ['/smart-enroll'],
        id: 'smart-enroll',
        permission: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'REVIEWER']
    },
    {
        separator: true
    },
    {
        label: 'Reports',
        icon: 'pi pi-calendar-plus',
        routerLink: ['/reports'],
        id: 'reports',
        permission: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'DEVICE_OPS']
    },
    {
        label: 'Administration',
        icon: 'pi pi-database',
        routerLink: ['/admin'],
        id: 'admin',
        permission: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'DEVICE_OPS']
    },
    {
        label: 'Settings',
        icon: 'pi pi-cog',
        routerLink: ['/settings'],
        id: 'settings',
        permission: ['ET_SUPER_ADMIN', 'CLIENT_SUPER_ADMIN', 'CLIENT_ADMIN', 'FLEET_MANAGER', 'REVIEWER', 'VIEWER', 'DEVICE_OPS']
    }
]

export function isRootUser() {
    var user = getLoggedUser()
    if (user != null && user['userType'] != undefined && user['userType'] == 'ROOT') {
        return true;
    }
    return false;
}

export function setLoggedUserRoles(loggedRoles: any) {
    sessionStorage.setItem('loggedUserRole', JSON.stringify(loggedRoles));
}

export function getLoggedUserRoles() {
    var loggedRole = sessionStorage.getItem('loggedUserRole');
    return loggedRole ? JSON.parse(loggedRole) : "";
}


export function setSelectedFleetData(fleetData: any) {
    sessionStorage.setItem('fleet', JSON.stringify(fleetData));
}

export function getSelectedFleetData(): any {
    var fleetData = sessionStorage.getItem('fleet');
    return fleetData ? JSON.parse(fleetData) : "";
}

export function setSelectedTimeRange(timerange: any) {
    sessionStorage.setItem('timeRange', JSON.stringify(timerange));
}

export function getSelectedTimeRange(): any {
    var timeRange = sessionStorage.getItem('timeRange');
    return timeRange;
}

export function setSelectedTimeRangeData(timerange: any) {
    if (Array.isArray(timerange)) {
        timerange.forEach((element: any) => {
            element = formatDate(new Date(element));
        });
    } else {
        timerange = formatDate(new Date(timerange));
    }
    sessionStorage.setItem('timeRangeData', JSON.stringify(timerange));
}

export function formatDate(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function getSelectedTimeRangeData(): any {
    const item: any = sessionStorage.getItem('timeRangeData');
    var timeRange: any = JSON.parse(item);
    return timeRange;
}

export function getSelectedFleetDataIdsAsString(): any {
    var fleetData: any = sessionStorage.getItem('fleet');
    const filterFleetIds = JSON.parse(fleetData).filter_fleet_ids.split(',');
    const uniqueFilterFleetIds = Array.from(new Set(filterFleetIds));
    return fleetData && filterFleetIds && uniqueFilterFleetIds ? uniqueFilterFleetIds : "";
}

export function setFleetsData(fleetData: any) {
    sessionStorage.setItem('totalFleetData', JSON.stringify(fleetData));
}

export function getFleetsData(): any {
    var fleetData = sessionStorage.getItem('totalFleetData');
    return fleetData ? JSON.parse(fleetData) : "";
}

export function setLoggedUserFleets(loggedRoles: any) {
    sessionStorage.setItem('loggedUserFleet', JSON.stringify(loggedRoles));
}

export function getLoggedUserFleets() {
    var userFleets = sessionStorage.getItem('loggedUserFleet');
    return userFleets ? JSON.parse(userFleets) : [];
}

export function setLoggedUser(loggedRoles: any) {
    sessionStorage.setItem('loggedUser', JSON.stringify(loggedRoles));
}

export function getLoggedUser() {
    var loggedUser = sessionStorage.getItem('loggedUser')
    return loggedUser ? JSON.parse(loggedUser) : [];
}

export function setFleetMap(fleetMap: any) {
    sessionStorage.setItem('fleetMap', JSON.stringify(fleetMap));
}

export function getFleetMap() {
    var fleetMap = sessionStorage.getItem('fleetMap')
    return fleetMap ? JSON.parse(fleetMap) : {};
}

export function setFleetNameMap(fleetMap: any) {
    sessionStorage.setItem('fleetNameMap', JSON.stringify(fleetMap));
}

export function getFleetNameMap() {
    var fleetMap = sessionStorage.getItem('fleetNameMap')
    return fleetMap ? JSON.parse(fleetMap) : {};
}


export const TOOLTIPS = [
    { name: "dashboard_opportunity_assessment", tooltip: "Shows potential revenue increase/decrease based on current operations. Click to naviagate to Opportunity Assessment Report." },
    { name: "dashboard_liability_assessment", tooltip: "Reflects possible cost reductions from managing liabilities efficiently.Click to navigate to Liability assesment report." },
    { name: "dashboard_driver_assessment", tooltip: "Indicates the percentage of drivers who have met or exceeded performance targets. Click to navigate to Driver Assesment report." },
    { name: "event_report_incorrect_snapshots", tooltip: "Number of snapshots identified as incorrectly categorized or analyzed." }
]

export const TIME_ZONES = [
    { "label": "Pacific/Midway", "value": "Pacific/Midway" },
    { "label": "America/Adak", "value": "America/Adak" },
    { "label": "Etc/GMT+10", "value": "Etc/GMT+10" },
    { "label": "Pacific/Honolulu", "value": "Pacific/Honolulu" },
    { "label": "Pacific/Marquesas", "value": "Pacific/Marquesas" },
    { "label": "Pacific/Gambier", "value": "Pacific/Gambier" },
    { "label": "America/Anchorage", "value": "America/Anchorage" },
    { "label": "America/Ensenada", "value": "America/Ensenada" },
    { "label": "Etc/GMT+8", "value": "Etc/GMT+8" },
    { "label": "America/Los_Angeles", "value": "America/Los_Angeles" },
    { "label": "America/Denver", "value": "America/Denver" },
    { "label": "America/Chihuahua", "value": "America/Chihuahua" },
    { "label": "America/Dawson_Creek", "value": "America/Dawson_Creek" },
    { "label": "America/Belize", "value": "America/Belize" },
    { "label": "America/Chicago", "value": "America/Chicago" },
    { "label": "America/Mexico_City", "value": "America/Mexico_City" },
    { "label": "America/Regina", "value": "America/Regina" },
    { "label": "America/Bogota", "value": "America/Bogota" },
    { "label": "America/New_York", "value": "America/New_York" },
    { "label": "America/Indiana/Indianapolis", "value": "America/Indiana/Indianapolis" },
    { "label": "America/Panama", "value": "America/Panama" },
    { "label": "America/Lima", "value": "America/Lima" },
    { "label": "America/Halifax", "value": "America/Halifax" },
    { "label": "America/Caracas", "value": "America/Caracas" },
    { "label": "America/La_Paz", "value": "America/La_Paz" },
    { "label": "America/Santiago", "value": "America/Santiago" },
    { "label": "America/St_Johns", "value": "America/St_Johns" },
    { "label": "America/Sao_Paulo", "value": "America/Sao_Paulo" },
    { "label": "America/Argentina/Buenos_Aires", "value": "America/Argentina/Buenos_Aires" },
    { "label": "America/Guyana", "value": "America/Guyana" },
    { "label": "America/Godthab", "value": "America/Godthab" },
    { "label": "Etc/GMT+2", "value": "Etc/GMT+2" },
    { "label": "Atlantic/Azores", "value": "Atlantic/Azores" },
    { "label": "Atlantic/Cape_Verde", "value": "Atlantic/Cape_Verde" },
    { "label": "Etc/GMT", "value": "Etc/GMT" },
    { "label": "Atlantic/Reykjavik", "value": "Atlantic/Reykjavik" },
    { "label": "Africa/Nouakchott", "value": "Africa/Nouakchott" },
    { "label": "Europe/Belfast", "value": "Europe/Belfast" },
    { "label": "Europe/Dublin", "value": "Europe/Dublin" },
    { "label": "Europe/Lisbon", "value": "Europe/Lisbon" },
    { "label": "Europe/London", "value": "Europe/London" },
    { "label": "Africa/Casablanca", "value": "Africa/Casablanca" },
    { "label": "Africa/Bangui", "value": "Africa/Bangui" },
    { "label": "Africa/Algiers", "value": "Africa/Algiers" },
    { "label": "Africa/Tunis", "value": "Africa/Tunis" },
    { "label": "Europe/Amsterdam", "value": "Europe/Amsterdam" },
    { "label": "Europe/Belgrade", "value": "Europe/Belgrade" },
    { "label": "Europe/Brussels", "value": "Europe/Brussels" },
    { "label": "Europe/Sarajevo", "value": "Europe/Sarajevo" },
    { "label": "Europe/Bratislava", "value": "Europe/Bratislava" },
    { "label": "Europe/Copenhagen", "value": "Europe/Copenhagen" },
    { "label": "Europe/Madrid", "value": "Europe/Madrid" },
    { "label": "Europe/Paris", "value": "Europe/Paris" },
    { "label": "Europe/Warsaw", "value": "Europe/Warsaw" },
    { "label": "Africa/Windhoek", "value": "Africa/Windhoek" },
    { "label": "Asia/Beirut", "value": "Asia/Beirut" },
    { "label": "Africa/Blantyre", "value": "Africa/Blantyre" },
    { "label": "Asia/Damascus", "value": "Asia/Damascus" },
    { "label": "Europe/Moscow", "value": "Europe/Moscow" },
    { "label": "Asia/Kuwait", "value": "Asia/Kuwait" },
    { "label": "Africa/Addis_Ababa", "value": "Africa/Addis_Ababa" },
    { "label": "Asia/Tehran", "value": "Asia/Tehran" },
    { "label": "Asia/Muscat", "value": "Asia/Muscat" },
    { "label": "Asia/Baku", "value": "Asia/Baku" },
    { "label": "Asia/Kabul", "value": "Asia/Kabul" },
    { "label": "Asia/Yekaterinburg", "value": "Asia/Yekaterinburg" },
    { "label": "Asia/Karachi", "value": "Asia/Karachi" },
    { "label": "Asia/Kolkata", "value": "Asia/Kolkata" },
    { "label": "Asia/Kathmandu", "value": "Asia/Kathmandu" },
    { "label": "Asia/Dhaka", "value": "Asia/Dhaka" },
    { "label": "Asia/Colombo", "value": "Asia/Colombo" },
    { "label": "Asia/Almaty", "value": "Asia/Almaty" },
    { "label": "Asia/Rangoon", "value": "Asia/Rangoon" },
    { "label": "Asia/Bangkok", "value": "Asia/Bangkok" },
    { "label": "Asia/Krasnoyarsk", "value": "Asia/Krasnoyarsk" },
    { "label": "Asia/Shanghai", "value": "Asia/Shanghai" },
    { "label": "Asia/Kuala_Lumpur", "value": "Asia/Kuala_Lumpur" },
    { "label": "Asia/Taipei", "value": "Asia/Taipei" },
    { "label": "Australia/Perth", "value": "Australia/Perth" },
    { "label": "Asia/Irkutsk", "value": "Asia/Irkutsk" },
    { "label": "Asia/Ulaanbaatar", "value": "Asia/Ulaanbaatar" },
    { "label": "Asia/Seoul", "value": "Asia/Seoul" },
    { "label": "Asia/Tokyo", "value": "Asia/Tokyo" },
    { "label": "Asia/Yakutsk", "value": "Asia/Yakutsk" },
    { "label": "Australia/Darwin", "value": "Australia/Darwin" },
    { "label": "Australia/Adelaide", "value": "Australia/Adelaide" },
    { "label": "Australia/Sydney", "value": "Australia/Sydney" },
    { "label": "Australia/Brisbane", "value": "Australia/Brisbane" },
    { "label": "Australia/Hobart", "value": "Australia/Hobart" },
    { "label": "Asia/Vladivostok", "value": "Asia/Vladivostok" },
    { "label": "Pacific/Guam", "value": "Pacific/Guam" },
    { "label": "Asia/Magadan", "value": "Asia/Magadan" },
    { "label": "Pacific/Fiji", "value": "Pacific/Fiji" },
    { "label": "Pacific/Auckland", "value": "Pacific/Auckland" },
    { "label": "Pacific/Tongatapu", "value": "Pacific/Tongatapu" }
]
