// src/app/store/actions/org-settings.actions.ts
import { createAction, props } from '@ngrx/store';

export const loadOrgSettings = createAction('[Org] Load Settings');
export const loadOrgSettingsSuccess = createAction('[Org] Load Settings Success', props<{ orgSettings: any }>());
export const loadOrgSettingsFailure = createAction('[Org] Load Settings Failure', props<{ error: any }>());

export const loadConversionRates = createAction('[Org] Load Conversion Rates', props<{ baseCurrency: string }>());
export const loadConversionRatesSuccess = createAction('[Org] Load Conversion Rates Success', props<{ convertFactor: any }>());
export const loadConversionRatesFailure = createAction('[Org] Load Conversion Rates Failure', props<{ error: any }>());
export const updateOrgSettings = createAction('[Org] Update Settings', props<{ orgSettings: any }>());
export const updateConvertedCurrency = createAction(
    '[Org Settings] Update Converted Currency',
    props<{ convertedCurrencyValue: any }>() // Adjust the type of convertedCurrencyValue as per your requirement
  );