import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	private orgSettingsUrl = environment.apiUrl + '/organizations/';
	private eventsListUrl = environment.apiUrl + '/events';
	private primaryEventsUrl = environment.apiUrl + '/primary_events';
	private compoundEventsUrl = environment.apiUrl + '/compound_events';
	private reportsUrl = environment.apiUrl + '/reports';
	private reportsFilesUrl = environment.apiUrl + '/reports/files/';

	constructor(private http: HttpClient) { }

	getOrgSettings(): Observable<any> {
		return this.http.get(this.orgSettingsUrl + JSON.parse(localStorage.getItem('user') || '{}').org_id);
	}

	updateOrgSettings(OrgData: any): Observable<any> {
		if (OrgData.hasOwnProperty('org_log') && OrgData['org_log'].includes(';base64,'))
			OrgData['org_log'] = OrgData['org_log'].split(';base64,')[1];
		// if (OrgData.hasOwnProperty('org_log')) {
		// 	OrgData['org_logo'] = OrgData['org_logo'].replaceAll('data:image/jpeg;base64,', '');
		// 	OrgData['org_logo'] = OrgData['org_logo'].replaceAll('data:image/png;base64,', '');
		// }
		return this.http.patch(this.orgSettingsUrl + JSON.parse(localStorage.getItem('user') || '{}').org_id, OrgData);
	}

	shareReports(user_id: string, widget_id: any, type: string): Observable<any> {
		const body = {
			user_id,
			widget_id,
			type,
			org_id: JSON.parse(localStorage.getItem('user') || '{}').org_id
		}
		return this.http.post(this.reportsUrl, body);
	}

	getReports(downloadWidgets: any): Observable<any> {
		return this.http.get(this.reportsUrl + "?widget_id=" + downloadWidgets);
	}

	getReportFiles(report_id: any) {
		return this.http.get(this.reportsFilesUrl + report_id);
	}

	downloadFile(file_url: any) {
		return this.http.get(file_url, { responseType: 'blob' });
	}

	getEventsList(): Observable<any> {
		return this.http.get(this.eventsListUrl);
	}

	updatePrimaryEvent(event: any): Observable<any> {
		return this.http.put(this.primaryEventsUrl, event);
	}

	resetPrimaryEvent(event_name: string): Observable<any> {
		return this.http.put(this.primaryEventsUrl + '/reset?event_name=' + event_name, null);
	}

	addCompoundEvent(compoundEvent: any): Observable<any> {
		return this.http.post(this.compoundEventsUrl, compoundEvent);
	}

	updateCompoundEvent(compoundEvent: any): Observable<any> {
		return this.http.put(this.compoundEventsUrl, compoundEvent);
	}
}
