<div class="layout-topbar mb-1">
    <div class="topbar-start">
        <h2 class="font-times-new-roman">
            <!-- <img [src]="orgLogo" alt="Org Logo">cloud<span style="color: #000;">AI</span></h2> -->
            <p-progressSpinner styleClass="w-2rem h-2rem" *ngIf="!orgLogo"></p-progressSpinner>
            <img [src]="orgLogo" alt="Org Logo" *ngIf="orgLogo">
        </h2>

        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>

        <!-- <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb> -->
    </div>
    <div class="topbar-end">
        <ul class="topbar-menu">

            <!-- <li>
                <p-treeSelect *ngIf="fleets" [(ngModel)]="selectedFleet" [options]="fleets" placeholder="Select Fleet" (onNodeSelect)="onFleetSelect($event)">
                    <ng-template let-node pTemplate="header">
                        {{selectedFleet.name}}
                    </ng-template>
                     <ng-template let-node pTemplate="default">
                        {{node.name}}
                    </ng-template>
                </p-treeSelect>

            </li> -->
            <li>
                <button (click)="op.toggle($event)" icon="pi pi-chevron-down" iconPos="right" pButton pRipple
                    type="button" [label]="'Fleet Group: ' + selectedFleet?.name"
                    class="p-button-outlined border-500 p-button-secondary bg-primary-reverse">
                </button>

                <p-overlayPanel [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #op [dismissable]="true"
                    [showCloseIcon]="false">
                    <ng-template pTemplate>
                        <p-tree [value]="fleets" selectionMode="single" [(selection)]="selectedFleet" [filter]="true"
                            placeholder="Select Fleet" [filterBy]="'name'" (onNodeSelect)="onFleetSelect($event)">
                            <ng-template let-node pTemplate="default">
                                {{node.name}}
                            </ng-template>
                            <ng-template let-item pTemplate="selectedItem">
                                {{selectedFleet.name}}
                            </ng-template>
                        </p-tree>
                    </ng-template>
                </p-overlayPanel>
            </li>
            <li>
                <!-- <label for="Fleet" class="mr-1 text-600">Fleet Group:</label> -->
                <!-- <p-panel [toggleable]="true" [collapsed]="true" [toggler]="'header'" expanded="false" 
                    appendTo="body" [expandIcon]="'pi pi-angle-up'" [collapseIcon]="'pi pi-angle-down'">
                    <ng-template pTemplate="header">
                        Fleet Group: <b> {{selectedFleet.name}}</b>
                    </ng-template>

                    <p-tree [value]="fleets" selectionMode="single" [(selection)]="selectedFleet" [filter]="true"
                        placeholder="Select Fleet" [filterBy]="'name'" (onNodeSelect)="onFleetSelect($event)">
                        <ng-template let-node pTemplate="default">
                            {{node.name}}
                        </ng-template>
                    </p-tree> 
                </p-panel>-->
            </li>

            <li class="topbar-profile">
                <button type="button" class="p-link" (click)="onProfileButtonClick()">
                    <img [src]="user.image || 'assets/demo/images/other/user.png'" alt="Profile" />
                </button>
                <div class="text-700 ml-2 p-link" (click)="onProfileButtonClick()" *ngIf="user">{{user.first_name}},
                    {{user.last_name}}</div>
            </li>
        </ul>
    </div>
</div>