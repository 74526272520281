import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as Constants from '@helpers/constants';
import { Observable, Subject, forkJoin, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
})
export class FleetsService {
    private baseFleetsUrl = environment.apiUrl + '/fleets';
    private csvFleetUrl: string = environment.apiUrl + '/bulk_fleets';
    private baseFleetsAggregationUrl = environment.apiUrl + '/fleet_coaching_summary';
    private baseFleetsAggregationForPeriodUrl = environment.apiUrl + '/fleet_performance/single';
    private baseFleetsFleetsPerformanceUrl = environment.apiUrl + '/fleet_performance';
    private baseFleetsAnalyticsUrl = environment.apiUrl + '/fleet_analytics/list';
    private baseFleetsummaryUrl = environment.apiUrl + '/fleet_summary';
    private baseEventsForFleetsAggregationForPeriodUrl = environment.apiUrl + '/fleet_event_report/daily';
    private baseEventsFleetReportUrl = environment.apiUrl + '/fleet_event_report';
    private baseDriverPerformanceUrl = environment.apiUrl + '/driver_assessment/daily';
    private baseFleetUtilizationUrl = environment.apiUrl + '/fleet_utilization/daily';
    private baseFleetCoachingReportDailyUrl = environment.apiUrl + '/fleet_coaching_report/daily';
    private baseLiabilityAssessmentUrl = environment.apiUrl + '/accident_avoidance';
    private baseFleetEventSummaryUrl = environment.apiUrl + '/fleet_event_summary';

    constructor(private http: HttpClient) { }

    getFleets(): Observable<any> {
        const httpOptions = {
            headers: { 'Access-Control-Allow-Headers': 'Content-Type', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS' },
        };
        return this.http.get(this.baseFleetsUrl, httpOptions);
    }

    getFleetsForFleet(fleet_id: string): Observable<any> {
        return this.http.get(this.baseFleetsUrl + '?id_=' + fleet_id);
    }

    getFleetSummary(fleet_ids: any): Observable<any> {
        const body = {
            fleet_ids
        }
        return this.http.post(this.baseFleetsummaryUrl, body);
    }

    private fleetLoaded = new Subject();
    fleetsSubjectLoaded$ = this.fleetLoaded.asObservable();

    loadFleets(): void {
        this.getFleets().subscribe((res: any) => {
            var fleetMap: any = {}
            var fleetNameMap: any = {}
            var fleetData = res;
            var totalFleets: any = [];
            fleetData.forEach((fleet: any) => {
                // if (!fleet.name.includes('(self)-')) {
                //     totalFleets.push(fleet);
                // }
                fleetMap[fleet.id_] = fleet.id_
                fleetNameMap[fleet.name] = fleet.name
            });
            // Constants.setFleetMap(fleetMap);
            // Constants.setFleetNameMap(fleetNameMap);
            Constants.setFleetsData(fleetData);
            this.fleetLoaded.next(true);
        });
    }

    getFleetsAnalytics(fromDate: string, toDate: string, fleetId: string): Observable<any> {
        const params = new HttpParams()
            .set('fromDate', fromDate)
            .set('toDate', toDate)
            .set('fleetId', fleetId)
            .set('aggregate_type', "WEEK")
            .set('aggregate', false)

        return this.http.post(this.baseFleetsAnalyticsUrl, params);
    }

    getFleetsAggregation(fromDate: string, toDate: string, fleetId: any, events: any, min_cabin_face_count?: number): Observable<any> {
        const body = {
            "from_date": fromDate,
            "to_date": toDate,
            "fleet_id": fleetId.split(","),
            events,
            // "aggregate_type": "WEEK",
            // "aggregate": true
            // min_cabin_face_count
        }

        return this.http.post(this.baseFleetsAggregationUrl, body);
    }

    getDriverPerformance(fromDate: string, toDate: string, fleetId: any): Observable<any> {
        const differenceInDays = Math.floor((new Date(toDate).getTime() - new Date(fromDate).getTime()) / (1000 * 3600 * 24));
        const body = {
            "from_date": fromDate,
            "to_date": toDate,
            "fleet_id": fleetId.split(","),
            "duration_type": differenceInDays > 7 ? "MONTHLY" : "WEEKLY",

            // "aggregate_type": "WEEK",
            // "aggregate": true
        }

        return this.http.post(this.baseDriverPerformanceUrl, body);
    }

    getFleetEventSummary(fromDate: string, toDate: string, fleetId: any, events: any): Observable<any> {
        const body = {
            "from_date": fromDate,
            "to_date": toDate,
            "fleet_id": fleetId.split(","),
            "events": events

            // "aggregate_type": "WEEK",
            // "aggregate": true
        }

        return this.http.post(this.baseFleetEventSummaryUrl, body);
    }

    getFleetsAggregationForPeriod(fromDate: string, toDate: string, fleetId: any): Observable<any> {
        const body = {
            "from_date": fromDate,
            "to_date": toDate,
            "fleet_id": fleetId.split(","),
        }

        return this.http.post(this.baseFleetsAggregationForPeriodUrl, body);
    }

    getFleetUtilizationDaily(fromDate: string, toDate: string, fleetId: any): Observable<any> {
        const body = {
            "from_date": fromDate,
            "to_date": toDate,
            "fleet_id": fleetId.split(","),
        }

        return this.http.post(this.baseFleetUtilizationUrl, body);
    }

    getFleetsPerformance(fromDate: string, toDate: string, fleetId: any): Observable<any> {
        const body = {
            "from_date": fromDate,
            "to_date": toDate,
            "fleet_id": fleetId.split(","),
        }

        return this.http.post(this.baseFleetsFleetsPerformanceUrl, body);
    }


    getLiabilityAssessment(fromDate: string, toDate: string, fleetId: any): Observable<any> {
        const body = {
            "from_date": fromDate,
            "to_date": toDate,
            "fleet_id": fleetId.split(","),
        }

        return this.http.post(this.baseLiabilityAssessmentUrl, body);
    }

    getSnapshotsForFleets(fromDate: string, toDate: string, fleetId: any, events: any): Observable<any> {
        const body = {
            "from_date": fromDate,
            "to_date": toDate,
            "fleet_id": fleetId.split(","),
            events
        }

        return this.http.post(this.baseEventsForFleetsAggregationForPeriodUrl, body);
    }

    getFleetCoachingReportDaily(fromDate: string, toDate: string, fleetId: any, events: any = ["inattention"]): Observable<any> {
        const body = {
            "from_date": fromDate,
            "to_date": toDate,
            "fleet_id": fleetId.split(","),
            "events": events
        }

        return this.http.post(this.baseFleetCoachingReportDailyUrl, body);
    }

    getFleetEventReport(fromDate: string, toDate: string, fleetId: any): Observable<any> {
        const body = {
            "from_date": fromDate,
            "to_date": toDate,
            "fleet_id": fleetId.split(","),
        }

        return this.http.post(this.baseEventsFleetReportUrl, body);
    }

    addFleet(fleetName: any, parentFleetId: any): Observable<any[]> {
        var json_data = {
            "name": fleetName,
            "parent_fleet_id": parentFleetId
        }

        return this.http.post(this.baseFleetsUrl, json_data)
            .pipe(
                map((res: any) => {
                    return res;
                }),
                catchError((error) => {
                    return throwError(error);
                }),
            );
    }

    addFleetsCsv(fileData: any[]): Observable<any[]> {
        let json_data = fileData;

        return this.http.post(this.csvFleetUrl, json_data).pipe(map((res: any) => {
            return res;
        }), catchError((error) => {
            return throwError(error);
        }));
    }


    editFleet(fleetName: any, fleetId: any): Observable<any[]> {
        var json_data = {
            "name": fleetName
        }
        var edit_fleet_url = this.baseFleetsUrl + '/' + fleetId
        return this.http.patch(edit_fleet_url, json_data)
            .pipe(
                map((res: any) => {
                    return res;
                }),
                catchError((error) => {
                    return throwError(error);
                }),
            );
    }
}
