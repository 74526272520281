// src/app/store/reducers/org-settings.reducer.ts
import { createReducer, on } from '@ngrx/store';
import { loadOrgSettingsSuccess, loadConversionRatesSuccess } from '../actions/org-settings.actions';
import { initialOrgSettingsState } from '../state/org-settings.state';

const _orgSettingsReducer = createReducer(
  initialOrgSettingsState,
  on(loadOrgSettingsSuccess, (state, { orgSettings }) => ({ ...state, orgSettings })),
  on(loadConversionRatesSuccess, (state, { convertFactor }) => ({ ...state, conversionRates: convertFactor }))
);

export function orgSettingsReducer(state: any, action: any) {
  return _orgSettingsReducer(state, action);
}
