import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EventsService {
    private baseCompoundEventsUrl = environment.apiUrl + '/compound_events';
    private baseEventLabelUrl = environment.apiUrl + '/event_label';
    private baseEventsUrl = environment.apiUrl + '/events';
    private baseEventsReviewsUrl = environment.apiUrl + '/snapshot/filter';
    private baseEventsByIdUrl = environment.apiUrl + '/snapshot/';
    private baseSnapshotDetailUrl = environment.apiUrl + '/snapshot/detail';
    private baseEventUrl = environment.apiUrl + '/events';
    private baseReviewUrl = environment.apiUrl + '/snapshot';
    private baseEventSummaryUrl = environment.apiUrl + '/snapshot_report_summary';
    private baseEventAndReviewsUrl = environment.apiUrl + '/snapshot/filter';
    private baseCoachingUrl = environment.apiUrl + '/snapshot/coaching';
    private baseCoachingUrlMultiple = environment.apiUrl + '/snapshot/coaching/multiple';
    private baseSnapshotCountUrl = environment.apiUrl + '/driver/snapshot/count';

    private dataHealthUrl = environment.apiUrl + '/data_health/list';


    constructor(private http: HttpClient) { }

    getEventsForDriver(from_date: string, to_date: string, driver_id: string, fleet_id: string): Observable<any> {
        const queryParams = "?from_date=" + from_date + "&to_date=" + to_date + "&driver_id=" + driver_id;
        return this.http.get(this.baseEventUrl + queryParams);
    }

    getSnapshotDetail(eventId: string): Observable<any> {
        return this.http.get(this.baseSnapshotDetailUrl + "/" + eventId);
    }

    getEventsForDevices(device_id: string, is_calibrated: boolean = false): Observable<any> {
        const queryParams = "?device_id=" + device_id + "&is_neutral_pose_calibration=" + is_calibrated
        return this.http.get(this.baseEventUrl + queryParams);
    }

    getReviewByEvent(eventId: string): Observable<any> {
        return this.http.get(this.baseReviewUrl + "?event_id=" + eventId);
    }

    saveReview(snapshot_id: string, reviewObj: any): Observable<any> {
        return this.http.patch(this.baseReviewUrl + "/coaching/" + snapshot_id, reviewObj);
    }

    getAllEventsForFleets(from_date: string, to_date: string, fleet_id: string, events: Array<string> = []): Observable<any> {
        const queryParams = "?from_date=" + from_date + "&to_date=" + to_date + "&fleet_ids=" + fleet_id + "&client_labels=" + events.toString() + "&et_labels=" + events.toString();
        return this.http.get(this.baseEventUrl + queryParams);
    }

    getAllEventsForFleetsPost(from_date: string, to_date: string, fleet_id: any, pagination: any, event_label?: Array<string>,
        coaching_data?: any, face_count?: number, device_id?: string[], driver_id?: string[],
        client_provided_id?: string[]): Observable<any> {

        var body = {};
        let min_cabin_face_count: any = 0;
        let max_cabin_face_count: any = 100;

        if (face_count != undefined && face_count === 0) {
            min_cabin_face_count = undefined;
            max_cabin_face_count = 0;
        } else if (face_count != undefined && face_count === 1) {
            min_cabin_face_count = 1;
            max_cabin_face_count = 1;
        } else if (face_count != undefined && face_count === 2) {
            min_cabin_face_count = 2;
            max_cabin_face_count = undefined;
        } else {
            min_cabin_face_count = undefined;
            max_cabin_face_count = undefined;
        }

        body = {
            pagination,
            fleet_id,
            to_date,
            from_date,
            event_label,
            coaching_data,
            min_cabin_face_count,
            max_cabin_face_count,
            device_id,
            driver_id,
            client_provided_id
        }
        return this.http.post(this.baseEventsReviewsUrl, body);
    }

    getSnapshotCount(from_date: string, to_date: string, fleet_id: any, driver_id: any, events: Array<string> = []): Observable<any> {
        var body = {};
        body = {
            driver_id,
            fleet_id,
            to_date,
            from_date,
            event_label: events,
        }
        return this.http.post(this.baseSnapshotCountUrl, body);
    }

    getEventById(event_id: string = ""): Observable<any> {
        return this.http.get(this.baseEventsByIdUrl + "/" + event_id);
    }

    getAllEventsAndReviewsForDriver(from_date: string, to_date: string, fleet_id: any, pagination: any, driver_id: any,
        coachingType?: any, coachingStatus?: any, reviewedBy?: any, event_label?: any, notification_status?: any): Observable<any> {
        let coaching_data: any = {};
        if (coachingType)
            coaching_data['coaching_type'] = coachingType;
        if (coachingStatus)
            coaching_data['coaching_status'] = coachingStatus;
        if (reviewedBy)
            coaching_data['coaching_reviewer_id'] = reviewedBy;

        // Select all statuses by default
        if (!coachingType.length) {
            coaching_data['coaching_type'] = ['COACHABLE', 'NOTCOACHABLE', 'UNREVIEWED'];
        }
        if (!coachingStatus.length) {
            coaching_data['coaching_status'] = ['PENDING', 'OVERDUE', 'COMPLETE', 'NA'];
        }
        if (!notification_status.length) {
            notification_status = ['EVENT_GENERATED', 'EVENT_SHARED', 'EVENT_VIEWED', 'SHARE_REQUESTED'];
        }

        const body = {
            pagination,
            fleet_id,
            to_date,
            from_date,
            driver_id,
            coaching_data,
            event_label,
            notification_status
        }
        return this.http.post(this.baseEventAndReviewsUrl, body);
    }

    getCompoundEvents(): Observable<any> {
        return this.http.get(this.baseCompoundEventsUrl);
    }

    addCompoundEvent(event: any): Observable<any> {
        return this.http.patch<any>(this.baseEventLabelUrl + "/128501f5-35ad-4caa-ba4f-0b5a253bd435", event);
    }

    getEventsLabels(): Observable<any> {
        return this.http.get(this.baseEventLabelUrl);
    }

    getEvents(): Observable<any> {
        return this.http.get(this.baseEventsUrl);
    }

    getEventsSummary(from_date: string, to_date: string, fleet_id: string, events: Array<string> = []): Observable<any> {
        const body = {
            "fleet_id": fleet_id.split(","),
            from_date,
            to_date,
            events
        }
        return this.http.post(this.baseEventSummaryUrl, body);
    }

    getDataHealth(selectedFleetObj: string): Observable<any> {
        const body = {
            "fleet_id": selectedFleetObj,
        }
        return this.http.post(this.dataHealthUrl, body);
    }

    updateSnapshots(snapshot_id: any, email_receivers: any, review_status: any, coaching_status: any, coaching_type: any, coaching_due_date: any, coaching_comments: any): Observable<any> {
        var body = {}
        body = {
            snapshot_id, email_receivers, review_status, coaching_status, coaching_type, coaching_due_date, coaching_comments
        }
        return this.http.patch(this.baseCoachingUrl + "/" + snapshot_id, body);
    }

    updateSnapshotsMultiple(snapshot_ids: any, email_receivers: any, review_status: any, coaching_status: any, coaching_type: any, coaching_due_date: any, coaching_comments: any): Observable<any> {
        var body = {}
        body = {
            snapshot_ids, email_receivers, review_status, coaching_status, coaching_type, coaching_due_date,
            coaching_comments
        }
        return this.http.patch(this.baseCoachingUrlMultiple, body);
    }
}
