import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DriverCoachingService {
	private baseUsersUrl = environment.apiUrl + '/v1/user/';
	private baseDriverScoreCardUrl = environment.apiUrl + '/driverscorecard/?token=';
	private baseDriverCoachingUrl = environment.apiUrl + '/fleet_coaching_report';
	private baseSmartEnrollUrl = environment.apiUrl + '/smart_enroll';
	private baseMediaUrl = environment.apiUrl + '/media?token=';
	private baseSmartEnrollFetchUrl = environment.apiUrl + '/fetch/smart_enrolls';
	private baseSmartEnrollEventIdUrl = environment.apiUrl + '/smart_enroll/snapshot';
	private basePatchSmartEnroll = environment.apiUrl + '/smart_enroll_action';
	private baseDriverScorecardListUrl = environment.apiUrl + '/driver_scorecard/list';
	private baseDriverScorecardFetchUrl = environment.apiUrl + '/driver_scorecard/fetch';

	constructor(private http: HttpClient) { }

	getDriverScorecardList(from_date: string, to_date: string, fleet_id: any, pagination: any): Observable<any> {
		const fromDateTime = new Date(from_date).getTime();
		const toDateTime = new Date(to_date).getTime();

		const currentTimeRange: any = sessionStorage.getItem('timeRange');
		var timeRange: any = parseInt(currentTimeRange);

		const body = {
			from_date,
			to_date,
			fleet_id,
			pagination,
			"duration_type": timeRange === 300 ? "MONTHLY" : "WEEKLY",
		};

		return this.http.post(this.baseDriverScorecardListUrl, body);
	}

	getDriverScorecardFetch(from_date: string, to_date: string, fleet_id: any, pagination?: any, driver_id?: any): Observable<any> {
		const body = {
			from_date,
			to_date,
			fleet_id,
			driver_id,
			pagination
		};

		return this.http.post(this.baseDriverScorecardFetchUrl, body);
	}

	getSmartEnrollDrivers(from_date: string, to_date: string, fleet_id: any, pagination: any, match_confidence_min: number, match_confidence_max: number, enroll_status: string = "UNREVIEWED", device_id: any, event_id: any): Observable<any> {
		const body = {
			from_date,
			to_date,
			fleet_id,
			pagination,
			match_confidence_min,
			match_confidence_max,
			enroll_status,
			device_id,
			snapshot_id: event_id ? [event_id] : undefined
		}

		return this.http.post(this.baseSmartEnrollFetchUrl, body);
	}

	getSmartEnrollByEventId(event_id: string): Observable<any> {
		return this.http.get(this.baseSmartEnrollEventIdUrl + "?client_provided_id=" + event_id);
	}

	getDriverCoachingAnalytics(from_date: string, to_date: string, fleet_id: any, pagination: any): Observable<any> {
		const body = {
			fleet_id,
			from_date,
			to_date,
			pagination

			// "aggregate_type": "WEEK",
			// "aggregate": true
		}

		return this.http.post(this.baseDriverCoachingUrl, body);
	}

	getDriverCoachingReport() {
		return this.http.get<any>(this.baseUsersUrl)
			.toPromise()
			.then(res => res.data as any[])
			.then(data => data);
	}

	getDriverScorecard(fromDate: string, toDate: string, fleetId: string): Observable<any> {
		return this.http.get<any>(this.baseDriverScoreCardUrl + JSON.parse(localStorage.getItem('user') || '{}').token + '&fromDate=' + fromDate + '&toDate=' + toDate + '&fleetId=' + fleetId);
	}

	getDriverCoaching(fromDate: string, toDate: string, fleetId: string, faceId: string): Observable<any> {
		return this.http.get<any>(this.baseDriverCoachingUrl + JSON.parse(localStorage.getItem('user') || '{}').token + '&fromDate=' + fromDate + '&toDate=' + toDate + '&fleetId=' + fleetId + '&faceId=' + faceId);
	}

	getSmartEnrollList(from_date: string, to_date: string, fleet_id: string): Observable<any> {
		fleet_id = encodeURIComponent(JSON.stringify(['string']))
		const queryParams = "?from_date=" + from_date + "&to_date=" + to_date + "&fleet_id=" + fleet_id + "&limit=200";
		return this.http.get<any>(this.baseSmartEnrollUrl + queryParams)
	}

	enrollDriver(id: string, driverObj: any): Observable<any> {
		return this.http.post(this.basePatchSmartEnroll + "/" + id, driverObj);
	}

	getUnknownFaceUrl(req_id: string): Observable<any> {
		return this.http.get<any>(this.baseMediaUrl + JSON.parse(localStorage.getItem('user') || '{}').token + '&mediaType=2&reqId=' + req_id);
	}

	//   getVerifyingFaceUrl(match_id: string): Observable<any> {
	// 	this.authService.getToken()
	// 	  .subscribe((token: NbAuthJWTToken) => {
	// 		this.token = token;
	// 	  });
	// 	var params = {}

	// 	params = {
	// 	  'token': this.token['token'],
	// 	  'faceId': match_id,
	// 	  'mediaType': 2
	// 	}

	// 	const httpOptions = {
	// 	  headers: {
	// 		'Content-Type': 'application/json',
	// 		'Accept': 'application/json',
	// 		'Access-Control-Allow-Headers': 'Content-Type',
	// 	  },
	// 	  params: params
	// 	};

	// 	return this.http.get(this.get_media_url, httpOptions)
	// 	  .pipe(
	// 		map((res) => {
	// 		  if (res['success'] < 0) {
	// 			// redirect page
	// 			return [];
	// 		  }
	// 		  this.updateAuthStatus.updateAuthStatus(res['token']);
	// 		  return res['data'];
	// 		}),
	// 		catchError((error) => {
	// 		  // redirect page
	// 		  return throwError(error);
	// 		}),
	// 	  );
	//   }


	getAllDriverScorecards() {
		return this.http.get<any>('assets/demo/data/driver_scorecard_all_data.json')
			.toPromise()
			.then(data => data);
	}

	getEventAnalysisReport() {
		return this.http.get<any>('assets/demo/data/operational-liability.json')
			.toPromise()
			.then(data => data);
	}

	getPerformanceSummary() {
		return this.http.get<any>('assets/demo/data/fleet_liability_performance_chart.json')
			.toPromise()
			.then(data => data);
	}

	getWorst5Drivers() {
		return this.http.get<any>('assets/demo/data/driver_scorecard_worst_5.json')
			.toPromise()
			.then(data => data);
	}

	getDriverScores() {
		return this.http.get<any>('assets/demo/data/driver_scorecard_with_scores.json')
			.toPromise()
			.then(data => data);
	}

}
