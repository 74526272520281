<div class="layout-sidebar">
    <a [routerLink]="['/']" class="app-logo"></a>

    <!-- <div class="layout-menu-container">
        <app-menu [bottom]="true"></app-menu>
    </div>

    <div class="flex flex-column align-items-center">
        <app-menu [bottom]="false"></app-menu>
    </div> -->

    <div class="page-flex-container">
        <div class="sidebar">
            <app-menu  ></app-menu>
          
          <div class="bottom">
            <!-- <app-menu  ></app-menu> -->
          </div>
          
        </div>

      </div>
</div>