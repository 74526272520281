import { ElementRef, Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

const EXCEL_EXTENSION = '.xlsx';
const CSV_EXTENSION = '.csv';

@Injectable({
    providedIn: 'root'
})
export class ExcelExportService {

    constructor() { }

    public exportData(data: any[], fileName: string, format: 'excel' | 'csv', htmlTable?: ElementRef): void {
        let ws: any;

        if (htmlTable) {
            ws = XLSX.utils.table_to_sheet(htmlTable.nativeElement);
        } else {
            ws = XLSX.utils.json_to_sheet(data);
        }

        const colWidth = 100; // Approximate character width

        if (ws['!cols']) {
            ws['!cols'].forEach((col: any) => {
                col.wpx = colWidth;
            });
        } else {
            ws['!cols'] = [];
            const range = XLSX.utils.decode_range(ws['!ref']);
            for (let i = range.s.c; i <= range.e.c; i++) {
                ws['!cols'].push({ wpx: colWidth });
            }
        }

        if (format === 'excel') {
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, fileName + EXCEL_EXTENSION);
        } else if (format === 'csv') {
            const csv = XLSX.utils.sheet_to_csv(ws);
            this.downloadCSV(csv, fileName + CSV_EXTENSION);
        }
    }

    private downloadCSV(csv: string, fileName: string): void {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
