import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DevicesService {
    private devicesBaseUrl = environment.apiUrl + '/devices';
    private csvDeviceUrl: string = environment.apiUrl + '/bulk_devices';
    private devicesFetchUrl = environment.apiUrl + '/fetch/devices';


    constructor(private http: HttpClient) { }

    _onCancelled<T>(callback: () => void) {
        return (obs: Observable<T>) => {
            let cancelled = true;
            return new Observable<T>(subscriber => {
                const sub = obs.subscribe(
                    value => {
                        if (value instanceof HttpResponse) cancelled = false;
                        subscriber.next(value);
                    },
                    err => {
                        cancelled = false;
                        subscriber.error(err);
                    },
                    () => {
                        cancelled = false;
                        subscriber.complete();
                    });
                return () => {
                    if (cancelled) callback();
                    sub.unsubscribe();
                };
            });
        };
    }

    getDevices(): Observable<any> {
        return this.http.get(this.devicesBaseUrl);
    }

    getDevicesFromFleetId(fleetId: String): Observable<any> {
        return this.http.get(this.devicesBaseUrl + "?fleet_id=" + fleetId);
    }

    addDevice(device: any): Observable<any> {
        return this.http.post(this.devicesBaseUrl, device)
            .pipe(
                map((res: any) => {
                    return res;
                }));
    }

    addDevicesCsv(fileData: any[]): Observable<any[]> {
        let json_data = fileData;

        return this.http.post(this.csvDeviceUrl, json_data)
            .pipe(map((res: any) => {
                return res;
            }), catchError((error) => {
                return throwError(error);
                // }), finalize(() => {
                //     return;
                // }), this._onCancelled(() => {
                //     return;
            }));
    }

    editDevice(device: any, device_id: any): Observable<any> {
        var device_url_by_device_id = this.devicesBaseUrl + '/' + device_id;
        return this.http.patch(device_url_by_device_id, device)
            .pipe(
                map((res: any) => {
                    return res;
                }),
                catchError((error) => {
                    return throwError(error);
                }),
            );
    }

    fetchDevicesForFleets(fleet_id: any) {
        const body = {
            fleet_id
        }
        return this.http.post(this.devicesFetchUrl, body);
    }
}
