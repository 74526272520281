import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class SpinnerService {
    private _spinner = new Subject<any>();
    public Spinner = this._spinner.asObservable();

    showSpinner() {
        this._toogleSpinner(true);
    }

    hideSpinner() {
        this._toogleSpinner(false);
    }

    private _toogleSpinner(flag: boolean) {
        this._spinner.next(flag);
    }
}