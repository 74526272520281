import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as env from '@environments/environment';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../service';


@Injectable({
    providedIn: 'root',
})
export class DriverAnalyticsService {
    constructor(private http: HttpClient,
        private authenticationService: AuthenticationService) { }

    private driver_url: string = env.environment.apiUrl + '/driver/';
    private drivers_url: string = env.environment.apiUrl + '/drivers';
    private drivers_fetch_url: string = env.environment.apiUrl + '/fetch/drivers';
    private drivers_get_url: string = env.environment.apiUrl + '/driver_details';
    private driver_score_url: string = env.environment.apiUrl + '/driverscorecard/';
    private driver_coaching_url: string = env.environment.apiUrl + "/drivercoaching/"
    private baseFleetsAggregationUrl = env.environment.apiUrl + '/driver_aggregation';
    private baseDriverEventAnalytics = env.environment.apiUrl + '/driver_event_analytics';
    private baseDriverAnalyticsListUrl = env.environment.apiUrl + '/drivers/list';
    private baseDriverCoachingReportUrl = env.environment.apiUrl + '/driver_coaching_report';
    private baseDriverTripReport = env.environment.apiUrl + '/driver_trip_report';

    getEnrolledDrivers(): Observable<any> {
        let user = this.authenticationService.userValue;
        let token = user?.token?.toString();
        const params = new HttpParams()
            .set('fleetId', env.fleetId)
            .set('token', (token) ? token : '');

        return this.http.get(this.driver_url + '?' + params.toString());
    }

    getDriverScores(fromDate: string, toDate: string): Observable<any> {
        let user = this.authenticationService.userValue;
        let token = user?.token?.toString();
        const params = new HttpParams()
            .set('fromDate', fromDate)
            .set('toDate', toDate)
            .set('fleetId', env.fleetId)
            .set('token', (token) ? token : '');

        return this.http.get(this.driver_score_url + '?' + params.toString());
    }

    getDriverCoachingReport(fromDate: string, toDate: string): Observable<any> {
        let user = this.authenticationService.userValue;
        let token = user?.token?.toString();
        const params = new HttpParams()
            .set('fromDate', fromDate)
            .set('toDate', toDate)
            .set('fleetId', env.fleetId)
            .set('token', (token) ? token : '');

        return this.http.get(this.driver_coaching_url + '?' + params.toString());
    }

    editEnrolledDriverDetails(driverObj: any): Observable<any> {
        let user = this.authenticationService.userValue;
        let token = user?.token?.toString();
        const params = new HttpParams()
            .set('driverName', driverObj.driverName)
            .set('employeeId', driverObj.employeeId)
            .set('token', (token) ? token : '');

        var driver = {}

        return this.http.patch(this.driver_url.concat(driverObj.faceId) + '?' + params.toString(), driver);
    }


    getDriverAggregation(from_date: string, to_date: string, fleet_id: any, pagination: any, driver_id?: any): Observable<any> {
        const body = {
            pagination,
            fleet_id,
            to_date,
            from_date,
            driver_id,
            // enable_all_data: true,
            // enable_coaching_data: true,
        }

        return this.http.post(this.baseFleetsAggregationUrl, body);
    }

    getDriverCoachingReportForDriver(from_date: string, to_date: string, fleet_id: any, pagination?: any, driver_id?: any): Observable<any> {
        const body = {
            pagination,
            fleet_id,
            to_date,
            from_date,
            driver_id,
        }

        return this.http.post(this.baseDriverCoachingReportUrl, body);
    }

    getDriverTripReport(from_date: string, to_date: string, fleet_id: any, pagination?: any, driver_id?: any): Observable<any> {
        const body = {
            pagination,
            fleet_id,
            to_date,
            from_date,
            driver_id,
        }

        return this.http.post(this.baseDriverTripReport, body);
    }

    getDriverAnalyticsList(from_date: string, to_date: string, fleet_id: any, pagination: any, driver_id?: any): Observable<any> {
        const body = {
            pagination,
            fleet_id,
            // to_date,
            // from_date,
            "show_image": true,
            driver_id,
        }

        return this.http.post(this.drivers_fetch_url, body);
    }

    getDriverEventAnalytics(fromDate: string, toDate: string, fleetId: any): Observable<any> {
        const body = {
            "fleet_id": [
                "string"
            ],
            "to_date": "2021-03-13",
            "from_date": "2021-03-11"
        }

        return this.http.post(this.baseDriverEventAnalytics, body);
    }

    getAllDrivers() {
        return this.http.get(this.drivers_url);
    }

    getAllDriversForFleets(fleet_id: any, driver_id?: any, pagination?: any) {
        const body = {
            fleet_id,
            driver_id,
            pagination,
            show_image: true
        }
        return this.http.post(this.drivers_fetch_url, body);
    }

    getDrivers(fleet_id: any, driver_id?: any, pagination?: any) {
        const body = {
            fleet_id,
            driver_id,
            pagination
        }
        return this.http.post(this.drivers_get_url, body);
    }

    getDriverDetail(driverId: string) {
        return this.http.get(this.drivers_url + "/" + driverId);
    }

    getDriverImage(driverId: string) {
        return this.http.get(this.drivers_url + "?driver_id=" + driverId);
    }

    deleteDriver(driverId: string) {
        return this.http.delete(this.drivers_url + "/" + driverId);
    }
}