
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { UsersRolesService } from './users-roles.service';


@Injectable({
    providedIn: 'root'
})
export class RoleService {

    constructor(private usersRolesService: UsersRolesService) { }

    roleWidgets: string[] = [];

    hasRole(expectedRoles: any): boolean {
        if (!localStorage.getItem('uiroles')) {
            this.usersRolesService.getUIRoles().subscribe(res => {
                localStorage.setItem("uiroles", JSON.stringify(res));
                return this.checkRole(expectedRoles);
            });
        } else {
            return this.checkRole(expectedRoles);
        }

        return true;
    }

    checkRole(expectedRoles: any): boolean {
        const token: any = localStorage.getItem('token');
        const decodedToken = this.getDecodedAccessToken(token);
        let currentUserRoles: any = decodedToken.roles;
        if (currentUserRoles == undefined) {
            currentUserRoles = decodedToken.user.roles;
        }
        const allRoles: any = localStorage.getItem('uiroles');
        let filteredRoles = JSON.parse(allRoles).filter((role: any) => role.id_ == currentUserRoles[0]);
        let roleWidgets: any;
        if (filteredRoles) roleWidgets = filteredRoles[0].widget_access_list;
        if (roleWidgets) {
            roleWidgets.forEach((element: any) => {
                this.roleWidgets.push(element.id_)
            });
        }

        return currentUserRoles.some((item: any) => expectedRoles.includes(item));
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwtDecode(token);
        } catch (Error) {
            return null;
        }
    }

    hasAccess(expectedWidgets: any): boolean {
        if (typeof (expectedWidgets) === 'string') {
            return this.roleWidgets.some((item: any) => expectedWidgets.includes(item));
        }
        else {
            let hasRole = false;
            this.roleWidgets.forEach((role: any) => {
                expectedWidgets.forEach((expectedRole: any) => {
                    if (role == expectedRole) {
                        hasRole = true;
                    }
                });
            });

            return hasRole;
        }
    }
}
