// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'https://staging-vaaas-api.edgetensor.com'
  // apiUrl: 'https://vaaas-api.edgetensor.com'
  // apiUrl: 'https://vaaas-demo-backend-v2-1495727222.us-east-1.elb.amazonaws.com'
  // apiUrl: 'https://staging.edgetensor.com:36036'
  // apiUrl: 'http://127.0.0.1:5000/v2',
  // apiUrl: 'https://api.edgetensor.ai/vaaas/v2',
//   apiUrl: 'https://api-staging.edgetensor.ai/v2'
  apiUrl: 'http://106.51.64.150/api',
  // apiUrl: 'http://demo-local.edgetensor.ai:1006/v2',
  // apiUrl: 'https://demo-api.edgetensor.ai/v2',
};

// Fleet Id
export const fleetId: string = "b7502aac-3984-4b9d-8278-2ab073d8e9f7"; // EKS Vehicle Tracking
// export const fleetId: string = "c5a6cc12-2115-4313-8078-5fdf546470b2"; //"2b4eec19-9ecb-4044-a4e4-6841023e76bc"; // ETDemo2023

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
