import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@services/index';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root',
})
export class DriverService {
    constructor(private http: HttpClient,
        private authenticationService: AuthenticationService) { }

    private getDriversUrl: string = environment.apiUrl + '/drivers';
    private fetchDriversUrl: string = environment.apiUrl + '/fetch/drivers';
    private editDriverUrl: string = environment.apiUrl + '/drivers';



    getDriverDetails(driverId: string): Observable<any> {
        return this.http.get(this.getDriversUrl + "/" + driverId);
    }

    getDriverImage(driverId: string) {
        return this.http.get(this.getDriversUrl + "/" + driverId);
    }

    getAllDrivers(): Observable<any> {
        return this.http.get(this.getDriversUrl);
    }

    fetchDrivers(from_date: string, to_date: string, fleet_id: any, id_?: any, pagination?: any): Observable<any> {
        const body = {
            pagination,
            fleet_id,
            // to_date,
            // from_date,
            id_,
            show_image: true
        }
        return this.http.post(this.fetchDriversUrl, body);
    }

    editDriver(driver: any): Observable<any> {
        return this.http.patch<any>(this.editDriverUrl + "/" + driver.id_, driver);
    }
}