import { Injectable } from '@angular/core';
import * as Constants from '@helpers/constants';
import { CHART_BG_COLORS, TIME_FILTER_TO_DATA_MAPPING, TIME_RANGE_FILTER_OPTIONS } from '@helpers/index';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { OrgSettingsState } from '../../demo/store/state/org-settings.state';
import * as moment from 'moment-timezone';

class Queue {
    items: any;
    first: any;
    last: any;
    constructor() {
        this.items = {}
        this.first = 0
        this.last = 0
    }
    enqueue(item: any) {
        this.items[this.last] = item
        this.last++
        return item + ' inserted'
    }
    dequeue() {
        const item = this.items[this.first]
        delete this.items[this.first]
        this.first++
        return item
    }
    peek() {
        return this.items[this.first]
    }
    get printQueue() {
        return this.items;
    }
    get length() {
        return this.last - this.first;
    }
    get isEmpty() {
        return this.length === 0;
    }
}



//     "fleetName": "Document Name",
//     "data": "Documents Folder",
//     "expandedIcon": "pi pi-folder-open",
//     "collapsedIcon": "pi pi-folder",
class FleetTreeNode {
    name: any;
    id_: any;
    label: any;
    data: any;
    children: Array<FleetTreeNode>;
    constructor(fleetData: any) {
        this.id_ = fleetData.id_;
        this.name = fleetData.name;
        this.label = fleetData.name;
        this.data = fleetData;
        this.children = [];
    }
}


@Injectable({
    providedIn: 'root',
})

export class UtilsService {
    constructor(private store: Store<OrgSettingsState>) { }

    createFleetTreeNode(fleet: any) {
        return new FleetTreeNode(fleet);
    }

    selectFleetTreeNode(fleetsTree: any, fleetId: any) {
        var fleetsQ = new Queue();
        fleetsTree.forEach((fleetTree: any) => {
            fleetsQ.enqueue(fleetTree)
        });
        var selectedFleet = fleetsQ.dequeue();
        if (selectedFleet.id_ == fleetId) {
            return selectedFleet;
        }
        while (selectedFleet.id_ != fleetId) {
            selectedFleet.children.forEach((child: any) => {
                fleetsQ.enqueue(child)
            });
            selectedFleet = fleetsQ.dequeue();
        }
        return selectedFleet;
    }

    convertFlatJsonToTree(array: any) {
        var map: any = {};
        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            obj.children = [];

            map[obj.fleetId] = obj;

            var parent = obj.parent_fleet_id || '-';
            if (!map[parent]) {
                map[parent] = {
                    children: []
                };
            }
            map[parent].children.push(obj);
        }
        return map['-'].children;
    }

    convertTreeJsonToFlat(array: any, parentid: any) {
        return array.reduce((r: any, o: any) => {
            var temp: any = {};
            r.push(temp);
            Object.entries(o).forEach(([k, v]) => {
                if (k.startsWith('child')) {
                    r.push(...this.convertTreeJsonToFlat(v, o._id));
                } else {
                    temp[k] = v;
                }
            });
            temp.parent_fleet_id = parentid;
            return r;
        }, []);
    }

    // Function to recursively flatten the hierarchical JSON provided in an array
    flattenFleetData(data: any[]): any[] {
        let result: any[] = [];

        // Recursively process each item in the array
        const processItem = (item: any) => {
            // Clone the item excluding the `children` property
            const { children, ...rest } = item;
            result.push(rest);

            // If there are children, process each child recursively
            if (children && children.length > 0) {
                children.forEach((child: any) => processItem(child));
            }
        };

        // Iterate over each element in the input array
        data.forEach(item => processItem(item));

        return result;
    }

    filterFleets(fleets: any, parentFleetId: any) {
        var parentFleetQ = new Queue();
        var filteredFleets: any = [];
        var listedFleetIds: any = []
        fleets.forEach((fleet: any) => {
            if (fleet.id_ == parentFleetId) {
                parentFleetQ.enqueue(fleet.id_)
                filteredFleets.push(fleet);
                listedFleetIds.push(fleet.id_);
            }
        });
        while (!parentFleetQ.isEmpty) {
            var parentFleetId = parentFleetQ.dequeue();
            fleets.forEach((flt: any) => {
                if (flt.parent_fleet_id == parentFleetId) {
                    parentFleetQ.enqueue(flt.id_)
                    filteredFleets.push(flt);
                    listedFleetIds.push(flt.id_);
                }
            });
        }
        return filteredFleets;
    }

    filterFleetRecursively(fleets: any, id: string): any {
        for (let fleet of fleets) {
            // Check if the current fleet matches the ID
            if (fleet.id_ === id) {
                return fleet;
            }

            // If the fleet has children, search recursively in the children
            if (fleet.children && fleet.children.length > 0) {
                let found = this.filterFleetRecursively(fleet.children, id);
                if (found) {
                    return found;
                }
            }
        }

        // Return null if no matching fleet is found
        return null;
    }

    // Convert flat array to tree view array
    convertFlatFleetsJsonToHierarchical(flatJSON: any) {
        const hierarchicalJSON: any[] = [];
        const fleetMap: { [id: string]: any } = {};

        flatJSON.forEach((fleet: any) => {
            fleet['key'] = fleet.id_;
            fleet['label'] = fleet.name;
        });

        flatJSON.forEach((fleet: any) => {
            fleetMap[fleet.id_] = { ...fleet, children: [] };
        });

        flatJSON.forEach((fleet: any) => {
            if (fleet.parent_fleet_id) {
                const parentFleet = fleetMap[fleet.parent_fleet_id];
                if (parentFleet) {
                    parentFleet.children.push(fleetMap[fleet.id_]);
                }
                else {
                    hierarchicalJSON.push(fleetMap[fleet.id_]);
                }
            } else {
                hierarchicalJSON.push(fleetMap[fleet.id_]);
            }
        });

        return hierarchicalJSON;
    }

    //Sorting the array
    convertFleetsToFleetTree(fleets: any, parentFleetIds: any = []) {
        var fleetQ = new Queue();
        var fleetTree: any = [];
        fleets.forEach((fleet: any) => {
            if ((parentFleetIds.length == 0 && fleet.parent_fleet_id == "")
                || (parentFleetIds.length > 0 && parentFleetIds.includes(fleet.id_))) {
                var newFleet = new FleetTreeNode(fleet);
                fleetTree.push(newFleet);
                fleetQ.enqueue(newFleet);
            }
        });
        while (!fleetQ.isEmpty) {
            var parentFleet = fleetQ.dequeue();
            fleets.forEach((flt: any) => {
                if (parentFleet.data.id_ == flt.parent_fleet_id) {
                    var newFleet = new FleetTreeNode(flt);
                    parentFleet.children.push(newFleet)
                    fleetQ.enqueue(newFleet)
                }
            });
        }
        return fleets.length > 0 && fleetTree.length < 1 ? fleets : fleetTree;
    }

    convertLongDatetoYYYMMDD(longDateString: string): string {
        const date = new Date(longDateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${year}-${month}-${day}   ${hours}:${minutes}:${seconds}`;
    }

    hexToRgba(hex: any, alpha?: any) {
        if (hex) {
            hex = hex.trim();
            var r = parseInt(hex.slice(1, 3), 16);
            var g = parseInt(hex.slice(3, 5), 16);
            var b = parseInt(hex.slice(5, 7), 16);
            if (alpha) {
                return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
            } else {
                return "rgb(" + r + ", " + g + ", " + b + ")";
            }
        } else {
            return CHART_BG_COLORS[Math.floor(Math.random() * 11)];
        }
    }

    getCalculatedTimeRange(toolbarComponent: any) {
        const timeRange = toolbarComponent?.selectedTimeRange;
        let filteredTimeRange: any;
        let actualTimeRangeToDisplay: any;
        if (timeRange == 70 || timeRange == 300) {
            filteredTimeRange = TIME_RANGE_FILTER_OPTIONS.filter((obj: any) => obj.start === toolbarComponent.fromDate)[0];
            actualTimeRangeToDisplay = TIME_FILTER_TO_DATA_MAPPING.filter((obj: any) => obj.start === toolbarComponent.fromDate);

        } else {
            filteredTimeRange = TIME_RANGE_FILTER_OPTIONS.filter((obj: any) => obj.value === timeRange)[0];
            actualTimeRangeToDisplay = TIME_FILTER_TO_DATA_MAPPING.filter((obj: any) => obj.value === filteredTimeRange.value);
        }
        const unitName = filteredTimeRange?.name || 'Selected Range';
        return { actualTimeRangeToDisplay, unitName };
    }

    getFilteredFleetsForSelectedFleet(allFleets: any, actualTimeRangeToDisplay: any) {
        if (actualTimeRangeToDisplay[0].value === 70 || actualTimeRangeToDisplay[0].value === 300) {
            const index = TIME_FILTER_TO_DATA_MAPPING.findIndex(obj => obj.start === actualTimeRangeToDisplay[0].start);
            actualTimeRangeToDisplay.push(TIME_FILTER_TO_DATA_MAPPING[index + 1]);
        }

        const filteredFleets = this.getFilteredFleets(allFleets);
        const thisTimeRangeData = filteredFleets[actualTimeRangeToDisplay[0].key];
        const previousTimeRangeData = filteredFleets[actualTimeRangeToDisplay[1]?.key];
        return { thisTimeRangeData, previousTimeRangeData };
    }

    getLabelForTimeValue(timeValue: any) {
        return TIME_RANGE_FILTER_OPTIONS.filter(obj => obj.value === timeValue)[0].name;
    }

    getFilteredFleets(allFleets: any) {
        var selectedFleet = Constants.getSelectedFleetData()
        let currentSelectedFleetName = selectedFleet ? selectedFleet.fleet_name : null;
        return allFleets.filter((obj: any) => (obj['name'] || obj['FleetName']) === currentSelectedFleetName)[0];
    }

    formatLabelForCharts(label: any) {
        if (Constants.DEFAULT_LABEL[label] != undefined) {
            label = Constants.DEFAULT_LABEL[label];
        }
        if (label) {
            label = label.replace(/_/g, " ");
            label = label.toLowerCase()
                .split(' ')
                .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
            return label;
        } else {
            return label;
        }
    }

    getFilteredTimeMapping(toolbarComponent: any) {
        const timeRange = toolbarComponent.selectedTimeRange;
        if (timeRange === 7 || timeRange === 70 || timeRange === 300) {
            return TIME_FILTER_TO_DATA_MAPPING.filter((item: any) => item.start === toolbarComponent.fromDate && item.value === toolbarComponent.selectedTimeRange)[0];
        } else {
            return TIME_FILTER_TO_DATA_MAPPING.filter((obj: any) => obj.value === timeRange)[0];
        }
    }

    getRandomNumber(min: any, max: any) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    getDateAndTime(dateStr: string, isDate: boolean) {
        const date = new Date(dateStr);
        return dateStr ? date.toISOString().split('T')[isDate ? 1 : 0] : '';
    }

    getScoreStyleClass(category: string) {
        if (category?.toLowerCase() === "excellent") {
            return CHART_BG_COLORS[1];
        }
        else if (category?.toLowerCase() === "fair") {
            return CHART_BG_COLORS[5];
        }
        else {
            return CHART_BG_COLORS[3];
        }
    }

    getScoreStyleCSSClass(category: string) {
        if (category?.toLowerCase() === "excellent") {
            return 'scorecard-excellent';
        }
        else if (category?.toLowerCase() === "fair") {
            return 'scorecard-fair';
        }
        else {
            return 'scorecard-risky';
        }
    }

    calculateDaysBetweenDates(date1: Date, date2: Date): number {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const differenceInTime = Math.abs(date2.getTime() - date1.getTime());
        return Math.ceil(differenceInTime / oneDay);
    }

    getPastDateWithDiffDays(date1: any, date2: any) {
        const fromDate = new Date(date1);
        const toDate = new Date(date2);
        const daysBetween = this.calculateDaysBetweenDates(fromDate, toDate);
        const pastDate = new Date(fromDate);
        pastDate.setDate(pastDate.getDate() - daysBetween);

        return pastDate;
    }

    getCountOfEvents(events: { [key: string]: number[] }): number {
        let sum = 0;
        for (const key in events) {
            if (events.hasOwnProperty(key)) {
                sum += events[key][0];
            }
        }
        return sum;
    }

    getCountsOfIndividualEvents(events: { [key: string]: number[] }): { [key: string]: number } {
        return Object.entries(events)
            .reduce((acc: any, [key, value]) => {
                acc[key] = value[0]; // Take only the 0th element of the array
                return acc;
            }, {});
    }

    getEventNamesFromEventsArray(data: any): string[] {
        const eventNames: string[] = [];

        if (data?.primary_events) {
            data.primary_events.forEach((event: any) => {
                if (eventNames.indexOf(event.name) == -1) {
                    eventNames.push(event.name);
                }
            });
        }

        if (data?.compound_events) {
            data.compound_events.forEach((compoundEvent: any) => {
                eventNames.push(compoundEvent.name);
                if (compoundEvent.constituents) {
                    compoundEvent.constituents.forEach((constituent: any) => {
                        if (eventNames.indexOf(constituent.name) == -1) {
                            eventNames.push(constituent.name);
                        }
                    });
                }
            });
        }

        return eventNames;
    }

    getEventDefaultLabelMapping(data: any): string[] {
        const event_map: any = {};

        if (data?.primary_events) {
            data.primary_events.forEach((event: any) => {
                event_map[event.name] = event.event_configuration.display_label;
            });
        }

        if (data?.compound_events) {
            data.compound_events.forEach((compoundEvent: any) => {
                event_map[compoundEvent.name] = compoundEvent.event_configuration.display_label;
                if (compoundEvent.constituents) {
                    compoundEvent.constituents.forEach((constituent: any) => {
                        event_map[constituent.name] = constituent.event_configuration.display_label;
                    });
                }
            });
        }

        return event_map;
    }

    getUniqueDriverAnalytics(data: any, shouldCombine: boolean = false) {
        let tempArray: any[] = [];
        if (shouldCombine) {
            data?.forEach((item: any) => {
                item?.forEach((driver: any) => {
                    tempArray.push(driver);
                });
            });
            data = tempArray;
        }

        const driverData: any = {};

        data.forEach((entry: any) => {
            const { driver_id, distance, event_count, score, events } = entry;

            if (!driverData[driver_id]) {
                driverData[driver_id] = { ...entry };
                driverData[driver_id].distance = [0, 0, 0, 0, 0];
                driverData[driver_id].event_count = [0, 0, 0, 0, 0];
                driverData[driver_id].score = 0;
                driverData[driver_id].events = {};
                driverData[driver_id].occurrences = 0; // Track occurrences of driver_id
            }

            for (let i = 0; i < distance.length; i++) {
                driverData[driver_id].distance[i] += distance[i];
            }

            for (let i = 0; i < event_count.length; i++) {
                driverData[driver_id].event_count[i] += event_count[i];
            }

            driverData[driver_id].score += score;
            driverData[driver_id].occurrences++; // Increment occurrences

            for (const event in events) {
                if (events.hasOwnProperty(event)) {
                    if (!driverData[driver_id].events[event]) {
                        driverData[driver_id].events[event] = [];
                    }
                    driverData[driver_id].events[event].push(...events[event]);
                }
            }
        });

        const compiledArray: any[] = Object.values(driverData).map((driver: any) => {
            const { distance, event_count, score, occurrences } = driver;
            const totalEvents = event_count.reduce((acc: any, curr: any) => acc + curr, 0);
            const avgScore = occurrences > 0 ? score / occurrences : 0; // Calculate average score considering occurrences
            return { ...driver, distance, totalEvents, score: avgScore };
        });

        return compiledArray;
    }

    convertDistance(distance: any, output_unit: any) {
        if (output_unit == Constants.SPEED_UNITS[0].value) {
            return (distance / 1000).toFixed(1);
        } else {
            return (distance * 0.000621371192).toFixed(1);
        }
    }

    get_31_days_of_date_range(from: Date, to: Date, interval_in_days: number): string[] {
        let dates: string[] = [];
        while (from < to) {
            dates = [...dates, new Date(from).toISOString().split('T')[0]];
            from.setDate(from.getDate() + interval_in_days);
        }
        return dates;
    }

    getOrgSettings() {
        const orgSettings = localStorage.getItem('org_settings');
        return JSON.parse(orgSettings || '{}');
    }

    getOrgCurrency() {
        const orgSettings = localStorage.getItem('org_settings');
        const currency = JSON.parse(orgSettings || '{}').currency;
        const currencySymbol = Constants.CURRENCIES.filter((curr: any) => curr.value === currency)[0]?.symbol;
        return currencySymbol || '$';
    }

    convertToTimezone(gmtTime: string, timezone: string): string {
        // Parse the GMT time (assuming the format is 'YYYY-MM-DD HH:mm:ss')
        const defaultTimezone = 'GMT';
        const format = 'YYYY-MM-DD HH:mm:ss';
        const gmtMoment = moment.utc(gmtTime, format);

        // Convert to the target timezone
        const targetTime = gmtMoment.tz(timezone || defaultTimezone).format(format);
        return targetTime;
    }

    // Get all child fleets for a given fleet ID
    getChildFleets(fleetId: string, hierarchicalFleets: any[]): string[] {
        let childFleets: any = [];

        const findFleetAndChildren = (fleetId: string, fleets: any[]) => {
            fleets.forEach((fleet: any) => {
                if (fleet.id_ === fleetId) {
                    const collectChildIds = (fleet: any) => {
                        fleet.children?.forEach((childFleet: any) => {
                            childFleets.push(childFleet.id_);
                            if (childFleet.children && childFleet.children.length > 0) {
                                collectChildIds(childFleet);
                            }
                        });
                    };

                    collectChildIds(fleet);
                } else if (fleet.children && fleet.children.length > 0) {
                    findFleetAndChildren(fleetId, fleet.children);
                }
            });
        };

        findFleetAndChildren(fleetId, hierarchicalFleets);

        return childFleets;
    }
}
