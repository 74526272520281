import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FleetsService, SpinnerService, UsersRolesService } from '@services/index';
import { Subscription, filter } from 'rxjs';
import { MenuService } from './app.menu.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { AppTopbarComponent } from './app.topbar.component';
import { LayoutService } from './service/app.layout.service';
@Component({
    selector: 'app-layout',
    templateUrl: './app.layout.component.html',
    styleUrls: ['./app.layout.component.scss']

})
export class AppLayoutComponent implements OnInit, OnDestroy {
    showSpinner = false;
    overlayMenuOpenSubscription: Subscription;
    containerClass: any;
    menuOutsideClickListener: any;

    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

    @ViewChild(AppTopbarComponent) appTopbar!: AppTopbarComponent;

    constructor(
        private spinnerService: SpinnerService, private cdr: ChangeDetectorRef,
        private menuService: MenuService,
        public layoutService: LayoutService,
        public renderer: Renderer2,
        public router: Router,
        public userRoleService: UsersRolesService,
        public fleetsService: FleetsService) {
    }
    showOutLet = false;
    loadLayout = false;
    ngOnInit(): void {
        this.userRoleService.setUserProfile();
        // this.userRoleService.rolesSubjectLoaded$.subscribe(() => {
        this.fleetsService.loadFleets();
        this.fleetsService.fleetsSubjectLoaded$.subscribe(() => {
            this.loadLayout = true;

        });
        // });
        this.containerClass = this.getContainerClass();
        // this.fleetsService.loadFleets();
        // this.userRoleService.setUserProfile();
        // this.fleetsService.getFleets().subscribe((res: any) => {
        //     var fleetMap: any = {}
        //     var fleetNameMap: any = {}
        //     var fleetData = res['data'];
        //     fleetData.forEach((fleet: any) => {
        //         fleetMap[fleet.fleetId] = fleet.name
        //         fleetNameMap[fleet.name] = fleet.fleetId
        //     });
        //     Constants.setFleetMap(fleetMap);
        //     Constants.setFleetNameMap(fleetNameMap);
        //     Constants.setFleetsData(fleetData);
        // });
    }

    afterTopLoaded(): void {
        this.showOutLet = true;
        this.overlayMenuOpenSubscription = this.layoutService.overlayOpen$.subscribe(() => {
            if (!this.menuOutsideClickListener) {
                this.menuOutsideClickListener = this.renderer.listen('document', 'click', event => {
                    const isOutsideClicked = !(this.appSidebar.el.nativeElement.isSameNode(event.target) || this.appSidebar.el.nativeElement.contains(event.target)
                        || this.appTopbar.menuButton.nativeElement.isSameNode(event.target) || this.appTopbar.menuButton.nativeElement.contains(event.target));
                    if (isOutsideClicked) {
                        this.hideMenu();
                    }
                });
            }

            if (this.layoutService.state.staticMenuMobileActive) {
                this.blockBodyScroll();
            }
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                this.hideMenu();
            });

        this.spinnerService.Spinner
            .subscribe((flag) => {
                this.showSpinner = flag;
            });
        this.cdr.detectChanges();  // Manually trigger change detection

    }
    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        }
        else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        }
        else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    hideMenu() {
        this.layoutService.state.overlayMenuActive = false;
        this.layoutService.state.staticMenuMobileActive = false;
        this.layoutService.state.menuHoverActive = false;
        this.menuService.reset();
        if (this.menuOutsideClickListener) {
            this.menuOutsideClickListener();
            this.menuOutsideClickListener = null;
        }
        this.unblockBodyScroll();
    }

    getContainerClass() {
        return {
            'layout-earth': this.layoutService.config.colorScheme === 'earth',
            'layout-et-light': this.layoutService.config.colorScheme === 'et-light',
            'layout-et-drive-forward': this.layoutService.config.colorScheme === 'et-drive-forward',
            'layout-dim': this.layoutService.config.colorScheme === 'dim',
            'layout-dark': this.layoutService.config.colorScheme === 'dark',
            'layout-colorscheme-menu': this.layoutService.config.menuTheme === 'colorScheme',
            'layout-primarycolor-menu': this.layoutService.config.menuTheme === 'primaryColor',
            'layout-transparent-menu': this.layoutService.config.menuTheme === 'transparent',
            'layout-overlay': this.layoutService.config.menuMode === 'overlay',
            'layout-static': this.layoutService.config.menuMode === 'static',
            'layout-slim': this.layoutService.config.menuMode === 'slim',
            'layout-horizontal': this.layoutService.config.menuMode === 'horizontal',
            'layout-static-inactive': this.layoutService.state.staticMenuDesktopInactive && this.layoutService.config.menuMode === 'static',
            'layout-overlay-active': this.layoutService.state.overlayMenuActive,
            'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
            'p-input-filled': this.layoutService.config.inputStyle === 'filled',
            'p-ripple-disabled': !this.layoutService.config.ripple
        }
    }

    ngOnDestroy() {
        if (this.overlayMenuOpenSubscription) {
            this.overlayMenuOpenSubscription.unsubscribe();
        }

        if (this.menuOutsideClickListener) {
            this.menuOutsideClickListener();
        }
    }

}
