<div class="spinner-container" *ngIf="!loadLayout">
    <div class="overlay"></div>
    <p-progressSpinner></p-progressSpinner>
</div>
<div *ngIf="loadLayout" class="layout-container" [ngClass]="containerClass">
    <app-sidebar></app-sidebar>
    <!-- <div class="spinner-container" *ngIf="showSpinner">
        <div class="overlay"></div>
        <p-progressSpinner></p-progressSpinner>
    </div> -->
    <div class="layout-content-wrapper">
        <app-topbar (topLoaded)="afterTopLoaded()"></app-topbar>
        <!-- <app-breadcrumb class="content-breadcrumb"></app-breadcrumb> -->
        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-profilemenu></app-profilemenu>
    <app-config></app-config>
    <div class="layout-mask"></div>
</div>