
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReportSchedulerService {
    private baseReportSchedulersUrl = environment.apiUrl + '/report_schedulers';
    private baseReportSchedulersWidgetsUrl = environment.apiUrl + '/report_scheduler_resources';
    private baseReportSchedulersHistoryUrl = environment.apiUrl + '/scheduled_reports';
    private baseReportSchedulersDownloadHistoryUrl = environment.apiUrl + '/resource_reports/files';

    constructor(private http: HttpClient) { }

    getReportSchedulers(): Observable<any> {
        return this.http.get(this.baseReportSchedulersUrl);
    }

    getWidgets(): Observable<any> {
        return this.http.get(this.baseReportSchedulersWidgetsUrl);
    }

    createScheduler(scheduler: any): Observable<any> {
        return this.http.post(this.baseReportSchedulersUrl, scheduler);
    }

    updateScheduler(scheduler: any): Observable<any> {
        return this.http.patch(this.baseReportSchedulersUrl + '/' + scheduler.id_, scheduler);
    }

    deleteScheduler(id: string): Observable<any> {
        return this.http.delete(this.baseReportSchedulersUrl + '/' + id);
    }

    getScheduledHistory(){
        return this.http.get(this.baseReportSchedulersHistoryUrl);
    }

    getHistoryReports(reportIds: string){
        return this.http.get(this.baseReportSchedulersDownloadHistoryUrl + '?resource_report_ids=' + reportIds);
    }
}