// src/app/store/state/org-settings.state.ts
export interface OrgSettingsState {
    orgSettings: any;
    conversionRate: any;
}

export const initialOrgSettingsState: OrgSettingsState = {
    orgSettings: {},
    conversionRate: {}
};
