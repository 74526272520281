import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../api';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public userSubject: BehaviorSubject<User | null>;
    public user: Observable<User | null>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
        this.user = this.userSubject.asObservable();
    }

    public get userValue() {
        return this.userSubject.value;
    }

    login(userEmail: string, userPassword: string): Observable<any> {
        const httpOptions = {
            headers: {
                'Content-Type': 'application/json'
            },
            observe: "response" as "body"
        };

        return this.http.post(`${environment.apiUrl}/login`, { email: userEmail, password: userPassword }, httpOptions)
            .pipe(map((user: any) => {
                let b_token = user.headers.get('Authorization')
                let token = b_token.split(" ")[1];
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user.body));
                return user.body;
            }), catchError((error: any) => {
                return throwError(error);
            }));;
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/auth/login']);
    }
}
