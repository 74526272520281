import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrgSettingsState } from '../state/org-settings.state';

export const selectOrgSettingsState = createFeatureSelector<OrgSettingsState>('orgSettings');

export const selectOrgSettings = createSelector(
    selectOrgSettingsState,
    (state: OrgSettingsState) => state.orgSettings
);

export const selectConversionRate = createSelector(
    selectOrgSettingsState,
    (state: OrgSettingsState) => state.conversionRate
);

export const selectCurrency = createSelector(
    selectOrgSettings,
    (orgSettings: any) => orgSettings ? orgSettings.currency : null
);

export const selectBaseCurrency = createSelector(
    selectOrgSettings,
    (orgSettings: any) => orgSettings ? orgSettings.baseCurrency : null
);
