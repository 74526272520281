import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SettingsService } from '@services/settings.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as OrgSettingsActions from '../actions/org-settings.actions';
import { OrgSettingsState } from '../state/org-settings.state';
import { selectOrgSettings } from '../selectors/org-settings.selectors';

@Injectable()
export class OrgSettingsEffects implements OnInit {
    loadOrgSettings$: any;
    loadConversionRates$: any;

    constructor(
        private actions$: Actions,
        private orgService: SettingsService,
        private http: HttpClient,
        private store: Store<OrgSettingsState>
    ) { }

    ngOnInit(): void {
        this.loadOrgSettings$ = createEffect(() => this.actions$.pipe(
            ofType(OrgSettingsActions.loadOrgSettings),
            mergeMap(() => this.orgService.getOrgSettings()
                .pipe(
                    map(orgSettings => {
                        return OrgSettingsActions.loadOrgSettingsSuccess({ orgSettings });
                    }),
                    catchError(error => {
                        console.error('Error loading Org Settings:', error);
                        return of(OrgSettingsActions.loadOrgSettingsFailure({ error }));
                    })
                ))
        ));

        this.loadConversionRates$ = createEffect(() => this.actions$.pipe(
            ofType(OrgSettingsActions.loadConversionRates),
            mergeMap((action: any) => this.http.get(`https://v6.exchangerate-api.com/v6/219776e3f335edf3648d4cf3/latest/${action.baseCurrency}`)
                .pipe(
                    map((response: any) => {
                        // console.log('Conversion Rates Loaded:', response);
                        return OrgSettingsActions.loadConversionRatesSuccess({ convertFactor: response.conversion_rates });
                    }),
                    catchError(error => {
                        console.error('Error loading Conversion Rates:', error);
                        return of(OrgSettingsActions.loadConversionRatesFailure({ error }));
                    })
                )),
            withLatestFrom(this.store.select(selectOrgSettings)), // Fixed to use selector function
            map(([action, orgSettings]) => {
                const baseCurrency = orgSettings.currency;
                const conversionRates = (action as any).convertFactor;
                const convertedCurrencyValue = conversionRates?.[baseCurrency];
                localStorage.setItem('conversionRateFactor', convertedCurrencyValue);
                return OrgSettingsActions.loadConversionRatesSuccess({ convertFactor: convertedCurrencyValue });
            })
        ));
    }
}