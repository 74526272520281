import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as Constants from '@helpers/constants';
import { AuthenticationService, SettingsService, UtilsService } from '@services/index';
import { UsersRolesService } from 'src/app/demo/service/users-roles.service';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { User } from '../demo/api';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopbarComponent implements OnInit, AfterViewInit {
    defaultOrgLogo: string = './favicon.ico';
    orgLogo: string;
    user: User;
    @Output() topLoaded: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('menubutton') menuButton!: ElementRef;
    selectedFleet: any;
    fleets: any;
    constructor(public layoutService: LayoutService,
        private utilsService: UtilsService,
        private settingsService: SettingsService,
        private usersRolesService: UsersRolesService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef) {
        this.usersRolesService.userSubjectRefreshed$.subscribe((updateUser: any) => {
            if (updateUser['image'] && !updateUser['image'].includes('http') && !updateUser['image'].includes(';base64,'))
                updateUser['image'] = 'data:image/jpeg;base64,' + updateUser['image'];
            this.user = updateUser;
        });

    }

    ngAfterViewInit() {
        this.topLoaded.emit(true);
    }
    ngOnInit() {
        var fleetData = Constants.getFleetsData();
        this.fleets = [];
        this.fleets = this.utilsService.convertFlatFleetsJsonToHierarchical(fleetData);

        //Hack to check default fleet and always shift to top
        var defaultFleet = this.fleets.filter((obj: any) => obj.id_.toLowerCase().includes(obj.org_id));
        if (defaultFleet.length > 0) {
            this.fleets = this.fleets.filter((obj: any) => !obj.id_.toLowerCase().includes(obj.org_id));
            this.fleets.unshift(defaultFleet[0]);
        }

        var oldSelectedFleet = Constants.getSelectedFleetData();
        if (!oldSelectedFleet.top_fleet_id) {
            this.selectedFleet = this.fleets.length > 1 ? this.fleets[1] : (this.fleets.length > 0 ? this.fleets[0] : '');
            Constants.setSelectedFleetData({
                "top_fleet_id": this.selectedFleet?.id_,
                // "filter_fleet_ids": this.extractIds(Constants.getFleetsData()),
                "filter_fleet_ids": "",
                "top_fleet_name": this.selectedFleet.name
            });
        } else {
            this.selectedFleet = fleetData.filter((obj: any) => obj.id_ === oldSelectedFleet.top_fleet_id)[0];
        }
      
        this.setUserProfile();
        this.getOrgSettings();
        this.cdr.detectChanges();
    }

    getOrgSettings() {
        this.settingsService.getOrgSettings().subscribe(res => {
            this.orgLogo = res.org_logo ? res.org_logo : this.defaultOrgLogo;
        });
    }

    extractIds(obj: any): string {
        const ids: string[] = [];

        function extractIdsRecursively(items: any[]): void {
            for (const item of items) {
                if (item.id_) {
                    ids.push(item.id_);
                }

                if (item.children && item.children.length > 0) {
                    extractIdsRecursively(item.children);
                }
            }
        }

        extractIdsRecursively(obj);
        return ids.join(",");
    }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onProfileButtonClick() {
        this.layoutService.showProfileSidebar();
    }

    onFleetTreeChanged() {
    }

    private setUserProfile() {
        const user: any = localStorage.getItem('user');
        this.user = JSON.parse(user || '');
        this.authenticationService.userSubject.subscribe((user: any) => {
            this.user = user;
        });
    }

    onFleetSelect(fleet: any) {
        this.selectedFleet = fleet.node;

        var fleetData = this.utilsService.filterFleets(Constants.getFleetsData(), this.selectedFleet.id_);
        var selected_sub_fleet = this.utilsService.convertFlatFleetsJsonToHierarchical(fleetData);
        const fleetObj = {
            "top_fleet_id": this.selectedFleet.id_,
            "filter_fleet_ids": this.extractIds(selected_sub_fleet),
            "top_fleet_name": this.selectedFleet.name
        }
        Constants.setSelectedFleetData(fleetObj);
        location.reload();
    }
}
