import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import * as Constants from '@helpers/constants';
import { Observable, of, throwError, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root',
})
export class UsersRolesService {
    private baseUsersUrl = environment.apiUrl + '/users';
    private baseRolesUrl = environment.apiUrl + '/roles';
    private baseUIRolesUrl = environment.apiUrl + '/ui/roles';
    private resetPasswordUrl = environment.apiUrl + '/reset_password';
    private changePasswordUrl = environment.apiUrl + '/users/change_password';
    private resourceGroupAccessUrl = environment.apiUrl + '/resource_group_access';
    private resourceGroupAccessMetadataUrl = environment.apiUrl + '/resource_group_access/metadata';


    constructor(private http: HttpClient, private router: Router) { }

    getUsers(): Observable<any> {
        const httpOptions = {
            headers: {
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            },
        };
        return this.http.get(this.baseUsersUrl, httpOptions);
    }

    private cache = new Map<string, HttpResponse<any>>();

    getCurrentUser(): Observable<any> {
        return of(localStorage.getItem('user'));
    }

    addUser(user: any): Observable<any> {
        return this.http.post<any>(this.baseUsersUrl, user).pipe(
            map((res: any) => {
                return res;
            }),
            catchError((error) => {
                return throwError(error);
            }),
        );
    }

    private userRefreshed = new Subject();
    userSubjectRefreshed$ = this.userRefreshed.asObservable();

    editUser(user: any): Observable<any> {
        var local_user_data = JSON.parse(localStorage.getItem('user') || '');
        if (user.hasOwnProperty('image') && user['image'].includes(';base64,'))
            user['image'] = user['image'].split(';base64,')[1];
        // if (user.hasOwnProperty('image')) {
        //     user['image'] = user['image'].replaceAll('data:image/jpeg;base64,', '');
        //     user['image'] = user['image'].replaceAll('data:image/png;base64,', '');
        // }
        if (user['id_'] == local_user_data['id_']) {
            let new_user = JSON.parse(JSON.stringify(user));
            if (new_user['image'] && !new_user['image'].includes('http') && !new_user['image'].includes(';base64,'))
                new_user['image'] = 'data:image/jpeg;base64,' + new_user['image'];
            new_user = { ...local_user_data, ...new_user };
            localStorage.setItem('user', JSON.stringify(new_user));
            this.userRefreshed.next(new_user);
        }
        return this.http.patch<any>(this.baseUsersUrl + "/" + user['id_'], user);
    }

    deleteUser(user: any): Observable<any> {
        return this.http.delete<any>(this.baseUsersUrl + "/" + user);
    }

    getRoles(): Observable<any> {
        const httpOptions = {
            headers: { 'Access-Control-Allow-Headers': 'Content-Type', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS' },
        };
        return this.http.get(this.baseRolesUrl, httpOptions);
    }

    getUIRoles(): Observable<any> {
        return this.http.get(this.baseUIRolesUrl);
    }

    getUIResourceGroupsMetadata(): Observable<any> {
        return this.http.get(this.resourceGroupAccessMetadataUrl);
    }

    addRole(role: any): Observable<any> {
        return this.http.post<any>(this.baseRolesUrl, role);
    }

    setUserProfile() {
        // this.storeService.fetchUserDetails();
        this.getCurrentUser().subscribe(
            (userDetails) => {
                var userData = JSON.parse(userDetails);
                Constants.setLoggedUser(userData);
                this.setFleets();
            },
            error => {
                // this.router.navigate(['/auth/login?returnUrl=%2Fdashboard']);
            }
        );
    }

    setFleets() {
        var totalFleets = Constants.getFleetMap();
        Constants.setLoggedUserFleets(Object.keys(totalFleets));
    }

    sentOTP(user: any) {
        return this.http.post<any>(this.resetPasswordUrl, user);
    }

    changePassword(user: any, id_: string) {
        return this.http.patch<any>(this.changePasswordUrl + "/" + id_, user);
    }

    getResourceGroupAccess() {
        return this.http.get<any>(this.resourceGroupAccessUrl);
    }

    addResourceGroup(rg: any): Observable<any> {
        return this.http.post<any>(this.resourceGroupAccessUrl, rg);
    }

    editResourceGroup(rg: any): Observable<any> {
        return this.http.put<any>(this.resourceGroupAccessUrl + '/' + rg.id_, rg);
    }

    deleteResourceGroup(rg: any): Observable<any> {
        return this.http.delete<any>(this.resourceGroupAccessUrl + '/' + rg.id_, rg);
    }
}
