import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as Constants from '@helpers/constants';
import { RoleService } from '@services/index';
import { filter, map } from 'rxjs/operators';
@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.scss']
})
export class AppMenuComponent implements OnInit {
    @Input() bottom = false;
    model: any[] = [];
    firstChildRoute: string = 'dashboard';

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private rolesService: RoleService) {
        var route = this.router.url.split('/')
        this.firstChildRoute = route.length > 0 ? route[1] : 'dashboard';
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                        this.firstChildRoute = route.snapshot.data['route'];
                    }
                    return route;
                })
            ).subscribe();
    }

    ngOnInit() {
        this.model = Constants.MENU_ITEMS;
    }

    hasPermission(menuItem: any) {
        return this.rolesService.hasRole(menuItem.permission);
    }
}
